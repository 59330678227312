[offCanvasContent] {
  display: none;
}

.offCanvas {
  $ml: 8.3333%;
  $zi: 1502;
  --ZI: #{$zi};
  $ZIBackdrop: $zi - 1;
  --ZIBackground: -2;
  --ZICircles: -1;
  position: fixed;
  z-index: -1;
  inset: 0;
  min-height: 100%;
  padding-top: 11px;
  background-clip: padding-box;
  outline: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    background-color: #fff;
    z-index: var(--ZIBackground);
  }
  
  &_active {
    transform: translateX(0);
    z-index: var(--ZI);
  }

  &__close {
    padding-right: 10px;
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
    @include circle($size: 40px, $color: #fff, $bgc: $c-grey, $fgc: $c-greynite);
  }

  &__backdrop {
    width: 100%;
    position: fixed;
    inset: 0;
    height: 100%;
    z-index: -1;
    background: $c-greynite;
    opacity: 0;
    transform: scaleZ(0);
    transition: transform 0.6s ease-in-out, opacity 0.6s ease-in-out;
    
    &_active {
      z-index: $ZIBackdrop;
      transform: scaleZ(1);
      opacity: 0.8;
    }
  }

  &__comparison {
    position: relative;
    padding-bottom: 20px;
  }

  & &__content {
    overflow-y: auto;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: $layout-width;
    padding-left: $layout-padding;
    padding-right: $layout-padding;
    width: 100%;
    padding-bottom: 26px;
  }

  &__comparison {
    padding-top: 10px;
    position: relative;

    &::before {
      content: '';
      @include background-svg($circle, null, calc(100% + #{$gutter * 2}), 100%);
      background-size: 547px;
      background-position: center -25%;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &__text {
    .pg__section {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &__logo {
    width: 120px;
    height: 76px;
  }

  [class^=ps__rail] {
    opacity: .5 !important
  }

  .ps__rail-y,
  .ps__thumb-y {
    width: 5px !important;
  }

  @media (min-width: $bp-m) {
    &::before {
      inset: 0;
    }

    &_active {
      &::before {
        inset: 0 0 0 calc(((100% - #{$layout-width}) / 2) + 5%);
      }
    }

    &::after {
      content: '';
      z-index: var(--ZICircles);
      @include background-svg($circle, null, 50vw, 100%);
      background-size: 1155px;
      background-position: 0 center;
      position: absolute;
      top: 0;
      right: 0;
    }

    & &__content {
      display: grid;
      grid-template-columns: 10% 1fr 1fr 1fr;
      grid-template-rows: auto 1fr;
      column-gap: 20px;
      grid-template-areas:
        ". a b b"
        ". c b b"
    }

    &__modalTitle {
      grid-area: a;
    }

    &__comparison {
      grid-area: b;
      align-self: center;

      &::before {
        content: none;
      }
    }

    &__modalBody {
      grid-area: c;
    }

    [class^=ps__rail] {
      opacity: 0 !important
    }
  }
}