/**
 * ==================== @ PAGINATION - NEWS
*/

.pagination {
	margin: 2rem 0;
	width: 100%;
	
	.pagination-list {
		display: flex;
		width: max-content;
		margin: 0 auto;

		.page-items {
			margin: 0 .3rem;
			text-align: center;
			
			a {
				background-color: $c-white;
				color: $c-grey;
				font-weight: $fw-bold;
				display: block;
				line-height: 44px;
				text-align: center;
				height: 44px;
				width: 44px;
				transition: background-color 150ms, color 150ms;
			}

			&.m--active a, &:hover a {
				background-color: $c-greynite;
				color: $c-white;
			}
		}
	}
}