.lp-text-image-wide {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 2rem;
  padding-block: 3rem;

  @media (min-width: $bp-s) {
    gap: 3rem;
  }

  @media (min-width: $bp-xl) {
    padding-block: 4rem;
  }
}

.lp-text-image-wide__content {
  flex-grow: 999;
  flex-basis: 0;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  min-width: calc(min(20rem, 100%));
}

.lp-text-image-wide__image {
  flex-grow: 1;
  flex-basis: 50%;
  min-width: calc(min(14rem, 100%));
  max-width: 28rem;
}
