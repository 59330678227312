.hero {
  position: relative;
}

.hero__image-wrapper {
  width: 100%;
  min-height: 176px;
  aspect-ratio: 2;

  @media (min-width: $bp-l) {
    height: calc(100vh - var(--headerHeight));
  }
}

.hero__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.hero__title-wrapper {
  margin-top: 1.5rem;
  padding-inline: $gutter;

  @media (min-width: $bp-s) {
    margin-top: 2rem;
  }

  @media (min-width: $bp-l) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: $layout-width;
    margin-top: 0;
    margin-inline: auto;
  }
}

.hero__title {
  margin: 0;
  font-size: 1.875rem;
  line-height: 1.25;
  color: $c-greynite;

  span {
    font-weight: 700;
  }

  @media (min-width: $bp-s) {
    font-size: 2.5rem;
  }

  @media (min-width: $bp-l) {
    max-width: 320px;
    font-size: 3.125rem;
  }
}
