/**
 * ==================== @ MAP
 */

.map {
    height: 100%;
    min-height: 300px;
    position: relative;
}

.map__view {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
}

.map__btn {
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
}

.leaflet-marker-pane {
	.leaflet-marker-icon#proximite{
		fill:  #000 !important;
		color: #000 !important;
	}
}

@media screen and (min-width:$bp-m) {
    .map__btn {
        left: 30px;
        transform: translateX(0);
    }
}