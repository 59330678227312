.diaporama {
  $ellipsePosition: -9%;
  margin-bottom: 0 !important;

  &__layer {
    overflow: hidden;
    background-color: $c-greynite;
    border-radius: $radius $radius $radius 0;
    position: relative;
    padding-left: $gutter;
    padding-right: $gutter;
  }

  &__slider {
    position: relative;
  }

  &__imgContainer {
    margin-bottom: 14px;
    position: relative;
    overflow: hidden;

    img {
      border-radius: $radius;
      aspect-ratio: 2.12;
      object-fit: cover;
    }
  }

  &__ellipse1,
  &__ellipse2 {
    pointer-events: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 165%;
    height: auto;
    aspect-ratio: 1;
  }

  &__ellipse1 {
    right: $ellipsePosition;
    fill: $c-greynite;
  }

  &__ellipse2 {
    display: none;
  }

  &__item {
    display: flex;
    flex-wrap: wrap;
  }

  &__imgContainer,
  &__block,
  &__videoContainer {
    @include colSize(100%);
  }

  &__videoContainer img {
    aspect-ratio: 1.3;
  }

  &__title,
  &__subTitle {
    color: $c-white;
    line-height: 26px;
    text-align: right;
    display: block;
  }

  &__title {
    font-weight: 600;
    font-size: em(16px);
    color: $c-green;

    span {
      color: #fff;
    }
  }

  &__subTitle {
    font-weight: 400;
    font-size: em(14px);
    color: #fff;

    span {
      color: $c-green;
    }
  }

  &__iconVideo {
    display: block;
  }

  &__playText {
    position: absolute;
    inset: 50% 0 0 50%;
    transform: translateX(-50%);
    z-index: 2;
    text-align: center;
    color: #fff;
    font-size: rem(20px);
    font-weight: 700;
    padding-top: 38px;
  }

  &__thumbVideo {
    padding: 0 !important;
  }

  &__interactions {
    margin-top: 13px;
    display: flex;
    justify-content: flex-end;
  }

  & &__pagination {
    display: flex;
    gap: 24px;
    position: absolute;
    bottom: calc(var(--diaporamabuttonHeight) / 2);
    width: calc(100% - var(--diaporamabuttonWidth) - 10px);
    transform: translateY(50%);
  }

  &__bullet {
    cursor: pointer;
    border-radius: 100%;
    width: 14px;
    height: 14px;
    background-color: $c-greenlite;

    &_active {
      background-color: $c-white;
    }
  }

  @media (min-width: 400px) {
    &__videoContainer img {
      aspect-ratio: 1.8;
    }
  }

  @media (max-width: 991px) {
    &.pg__section {
      padding: 0;
      border-radius: 0;
      margin: 0;
    }
  }

  @media (min-width: 992px) {

    &.pg__section,
    &__layer {
      padding-left: 0;
      padding-right: 0;
      border-radius: 0;
    }

    &__layer {
      &::after {
        content: '';
        display: block;
        height: 10px;
        background: linear-gradient(90deg, $c-green 0%, $c-green 72%, rgba($c-green, .6) 72%, rgba($c-green, .6) 100%);
      }
    }

    &__content {
      padding: 0 !important;
    }

    &__imgContainer {
      @include colSize(60%);
    }

    &__block {
      @include colSize(32%);
      padding: 0 0 var(--diaporamapaginationHeight) 0 !important;
    }

    &__slide {
      height: 380px;
    }

    &__item {
      align-items: center;
    }

    &__imgContainer,
    &__videoContainer {
      margin-bottom: 0;

      img {
        height: 380px;
        width: 100%;
        border-radius: 0;
      }
    }

    &__videoContainer {
      img {
        object-fit: cover;
        object-position: center;
      }
    }

    &__ellipse2 {
      display: block;
      right: calc(#{$ellipsePosition} + 100px);
      fill: rgba(#fff, .8);
    }

    &__title,
    &__subTitle {
      line-height: 38px;
    }

    &__title {
      font-size: 32px;
    }

    &__subTitle {
      font-size: 20px;
    }

    & &__pagination {
      transform: translateY(0);
      height: 35px;
      width: 50%;
      align-items: flex-start;
      justify-content: flex-end;
      inset: auto 8.3333% 0 auto;
    }
  }
}