.lp-form-trigger {
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 100%;
  min-height: 50px;
  padding-block: 0.375rem;
  padding-inline: 1.5rem 1rem;
  background-color: $c-green;
  box-shadow: 0 -4px 4px rgba(0, 0, 0, 0.25);

  @media (min-width: $bp-1280) {
    display: none;
  }
}

.lp-form-trigger__label {
  font-size: 1.375rem;
  font-weight: 600;

  span {
    font-weight: 800;
  }
}

.lp-form-trigger__icon {
  flex-shrink: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 50%;
  outline: 4px solid rgba($c-white, 0.5);
  background-color: rgba($c-white, 0.8);

  .svg-icon {
    font-size: 0.625rem;
  }
}

.lp-form-layer {
  position: relative;
  padding: 1.5rem 1.25rem;
  background-color: $c-white;

  @media (min-width: $bp-s) {
    padding-inline: 2rem;
  }

  @media not all and (min-width: $bp-1280) {
    display: none;
    position: fixed;
    inset: 0;
    overflow: auto;

    &.is-open {
      display: block;
    }
  }
}

.lp-form-close {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 1.25rem;
  right: 1.25rem;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  outline: 4px solid $c-greydark;
  background-color: $c-greynite;
  color: $c-white;

  .svg-icon {
    font-size: 0.625rem;
    color: inherit;
  }

  @media (min-width: $bp-s) {
    width: 2rem;
    height: 2rem;

    .svg-icon {
      font-size: 0.75rem;
    }
  }

  @media (min-width: $bp-1280) {
    display: none;
  }
}

.lp-tel-trigger {
  flex-shrink: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  right: 1.25rem;
  bottom: 1.25rem;
  width: 2.25rem;
  height: 2.25rem;
  border-radius: 50%;
  outline: 4px solid rgba($c-green, 0.5);
  background-color: $c-green;
  z-index: 1;

  .svg-icon {
    font-size: 1.125rem;
  }

  @media (min-width: $bp-1280) {
    position: absolute;
    top: 1.25rem;
    bottom: auto;
  }
}

.lp-tel-tooltip {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 20rem;
  max-width: calc(100% - 1.25rem * 2);
  z-index: 100;

  &::before {
    content: '';
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: -1;
    pointer-events: none;
  }

  @media (min-width: $bp-1280) {
    position: absolute;
    max-width: calc(100% - 3rem * 2);
  }
}

.lp-tel-list {
  padding: 1.5rem;
  background-color: $c-white;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.25);

  @extend .l-unlist;
}

.lp-tel-list-item {
  display: flex;
  align-items: baseline;
  gap: 0.5rem;
  font-size: 0.9375rem;
  color: $c-greydark;
}

.lp-tel-list-item__label {
  align-items: baseline;
  flex-grow: 1;
  display: flex;
  gap: 0.5rem;

  &::after {
    content: '';
    flex-grow: 1;
    height: 1px;
    background-color: $c-green;
  }
}

.lp-form-wrapper {
  position: relative;
}

.lp-form {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 1.5rem 1rem;

  @media (min-width: $bp-s) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

.lp-form-item {
  display: flex;
  flex-direction: column;

  label {
    font-size: 0.75rem;
    font-weight: 500;
    color: $c-label;
  }

  input[type='text'],
  input[type='email'],
  input[type='tel'],
  input[type='password'],
  input[type='number'],
  input[type='date'],
  input[type='time'],
  input[type='datetime-local'],
  input[type='file'],
  select,
  textarea {
    width: 100%;
    border-width: 1px;
    border-style: solid;
    border-color: $c-label;
    outline: none;
    background-color: transparent;
    font-family: $font-stack;
    font-size: 1rem;
    font-weight: 500;
    color: $c-grey;
    transition-property: border-color;
    transition-duration: 150ms;

    &::placeholder {
      color: $c-label;
      opacity: 1;
    }

    &:focus-visible {
      border-color: $c-black;
    }
  }

  input[type='text'],
  input[type='email'],
  input[type='tel'],
  input[type='password'],
  input[type='number'],
  input[type='date'],
  input[type='time'],
  input[type='datetime-local'],
  input[type='file'],
  select {
    height: 2.625rem;
    padding-block: 0.5rem;
    padding-inline: 0.5rem;
    border-top: none;
    border-right: none;
    border-left: none;
  }

  textarea {
    min-height: 8.625rem;
    padding: 1rem;
    border-radius: 3px;
    resize: vertical;
  }

  select {
    appearance: none;
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6" fill="none"><path d="M4.64645 5.14645L0.853553 1.35355C0.538571 1.03857 0.761654 0.5 1.20711 0.5H8.79289C9.23835 0.5 9.46143 1.03857 9.14645 1.35355L5.35355 5.14645C5.15829 5.34171 4.84171 5.34171 4.64645 5.14645Z" fill="%23656566"/></svg>');
    background-size: 0.625rem auto;
    background-position: right 0.5rem top 50%;
    background-repeat: no-repeat;
    color: $c-label;

    &:valid {
      color: $c-grey;
    }

    option {
      font-weight: 500;
      color: $c-grey;
    }
  }

  &--checkbox {
    flex-wrap: wrap;
    flex-direction: row;
    align-items: flex-start;

    label {
      flex-grow: 1;
      flex-basis: 0;
      padding-left: 0.5rem;
      font-family: $font-stack;
      font-size: 0.75rem;
      font-weight: 400;
      color: $c-greydark;

      a {
        color: $c-greendark;

        &:hover,
        &:focus-visible {
          text-decoration-line: underline;
        }
      }
    }

    input[type='checkbox'] {
      appearance: none;
      flex-shrink: 0;
      height: 1rem;
      width: 1rem;
      border: 1px solid $c-label;
      border-radius: 3px;
      outline: none;
      background-color: transparent;
      transition-property: border-color, background-color;
      transition-duration: 150ms;

      &:focus-visible {
        border-color: $c-black;
      }

      &:checked {
        background-color: $c-grey;
      }
    }

    .lp-form-item__info,
    .lp-form-item__error {
      flex-basis: 100%;
    }
  }
}

.lp-form-item__info {
  margin-top: 0.375rem;
  font-size: 0.75rem;
  color: $c-greynite;
}

.lp-form-item__error {
  margin-top: 0.5rem;
  font-size: 0.875rem;
  color: $c-red;
}
