.footer {
  --logo-width: 100px;

  background-color: $c-whitegrey;
}

.footer-inner {
  max-width: $layout-width;
  margin-inline: auto;
  padding-inline: $gutter;
}

.footer-layout {
  display: flex;
  justify-content: space-between;
  gap: 1.5rem;
  position: relative;
  min-height: 168px;
  padding-block: 2rem;

  @media (min-width: $bp-s) {
    flex-direction: column;
    justify-content: center;
  }

  @media (min-width: $bp-l) {
    padding-right: calc(2rem + var(--logo-width));
  }
}

.footer__links-wrapper {
  .footer__link {
    font-weight: 600;
  }
}

.footer__aside-links-wrapper {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 1.5rem;

  @media not all and (min-width: $bp-s) {
    align-items: flex-end;
  }

  @media (min-width: $bp-l) {
    flex-direction: row;
    align-items: center;
  }

  .footer__links {
    @media not all and (min-width: $bp-s) {
      align-items: flex-end;
    }
  }

  .footer__link {
    @media not all and (min-width: $bp-s) {
      text-align: right;
    }
  }
}

.footer__links {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;

  @media not all and (min-width: $bp-s) {
    flex-direction: column;
  }
}

.footer__link-wrapper {
  display: inline-flex;
}

.footer__link {
  letter-spacing: normal;
  color: $c-greydark;
  transition-property: color;
  transition-duration: 150ms;

  &:hover,
  &:focus-visible {
    color: $c-green;
  }
}

.footer__logo-wrapper {
  display: inline-flex;

  @media not all and (min-width: $bp-s) {
    order: -1;
  }

  @media (min-width: $bp-l) {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }
}

.footer__logo {
  width: var(--logo-width);
}
