.service-edito {
    $gap: 15px;

    h2, h3 {
        font-weight: 600;
        line-height: 1.25;
        font-size: 20px;
        text-transform: uppercase;
    }

    .layout-txt-img {
        display: flex;
        flex-direction: column;

        .grid-col-img {
            order: 0;
        }

        .grid-col-txt {
            order: 1;
            align-items: flex-start;
            justify-content: flex-start;
        }

        .edito-wrapper-slider {
            order: 2;
        }

        .edito-blockButton {
            order: 3;
        }
    }

    .layout-img-txt {
        .grid-col-txt {
            align-items: flex-start;
        }
    }

    .edito-button {
        background-color: $c-greynite;
        border-radius: $radius;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        padding: 35px;
        gap: $gap;

        p {
            color: $c-white;
        }

        div {
            width: 100%;

            &:last-child {
                text-align: center;
            }
        }

        a {
            padding: .75rem 1rem;
        }

    }

    @media (min-width: $bp-m) {
        .edito-section {
            &.edito-caption {
                padding-left: 65px;
                padding-right: 65px;
            }
    
            .layout-txt-img {
                flex-direction: row;
    
                .grid-col-txt {
                    padding-left: 45px;
                    padding-right: 40px;
                }
    
                >* {
                    order: unset;
                }
            }
    
            .layout-img-txt {
                .grid-col-txt {
                    justify-content: flex-end;
                    text-align: right;
                    padding-right: 45px;
                    padding-left: 40px;
                }
            }
    
            .grid-layout {
                padding: 15px;
            }
    
            .edito-button {
                padding: 1rem 4rem;
    
                div {
                    width: calc(50% - #{$gap});
                }
    
                div:last-child {
                    text-align: right;
                }
            }
        }

        .grid-col-txt {
            padding-top: 2rem;
        }
    }
}
