.lp-reviews {
  padding-block: 3rem;

  @media (min-width: $bp-xl) {
    padding-block: 4rem;
  }
}

.lp-reviews__content {
  :where(p, a, button) {
    all: unset;
  }

  .skeepers_carousel_container {
    gap: 2rem;
    width: auto;
    margin-bottom: 0;

    @media not all and (min-width: $bp-s) {
      flex-direction: column;
    }

    .rating__value {
      align-self: center;
      flex-shrink: 0;
      width: 150px !important;
      max-width: none !important;
      min-width: auto !important;
      margin: 0 !important;

      @media (min-width: $bp-s) {
        align-self: flex-start;
      }
    }

    .rating__details-text,
    .rating__details-link {
      margin-inline: 0 !important;
    }

    .carousel-badge-tooltip {
      display: none;
    }

    .carousel-container {
      flex-grow: 1;
      position: relative;
      width: auto;
      height: 240px;
      box-sizing: border-box;
    }

    .skeepers_carousel-main {
      display: initial;
      position: absolute;
      inset: 0;
      height: 200px;
      max-height: none;
      padding-inline: 2rem;

      .slide-arrow {
        justify-content: center;
        margin: 0;

        &#slide-arrow-prev,
        &#slide-arrow-next {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          padding-inline: 0;
          border-radius: 0;
        }

        &#slide-arrow-prev {
          left: 0;
        }

        &#slide-arrow-next {
          right: 0;
        }
      }

      .skeepers-swiper {
        width: 100%;
        height: 100%;
        margin: 0;
        box-sizing: border-box;
      }
    }

    .carousel__navigation {
      display: flex;
      justify-content: center;
      gap: 0.375rem;
      position: absolute;
      top: auto;
      bottom: 0 !important;
      margin-bottom: 0;
      overflow-x: hidden;

      .swiper-pagination-bullet {
        flex-shrink: 0;
        margin: 0 !important;
      }
    }
  }
}
