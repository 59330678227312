.realisations {
  $el: &;
  margin-top: 30px;
  padding-top: 48px;
  padding-bottom: 48px;
  background-color: $c-whitegrey;

  &__slide {
    overflow: hidden;
  }

  &__controls {
    display: flex;
    align-items: flex-end;
    gap: 15px;
    margin-top: 18px;
  }

  &__prev,
  &__next {
    @include circle($size: 40px, $color: $c-white, $bgc: #3b4f60, $fgc: #596b79);
    transition: opacity .2s;

    &[aria-disabled="true"] {
      opacity: 0.5;
    }
  }

  &__pagination {
    font-weight: 500;
    font-size: 22px;
    padding-left: 5px;
  }

  &__picto {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  &__installation-type {
    font-weight: 700;
    color: $c-white;
    line-height: 1.2;
    font-size: 15px;
    display: block;
    padding-bottom: .5em;
  }

  @media (min-width: 1152px) {
    &__wrapper {
      display: grid;
      grid-template-rows: repeat(1, 1fr);
      grid-template-columns: repeat(12, 1fr);
      gap: 20px;
      justify-content: center;
    }

    &__slide {
      grid-column: auto/span 4;
    }

    &__controls {
      display: none;
    }
  }
}