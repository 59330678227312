/**
 * ==================== @ BASE STYLES
 */

@font-face {
  font-family: 'poppins';
  src: url(path('Poppins-Light.woff2', $font-path)) format('woff2');
  font-weight: $fw-light;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'poppins';
  src: url(path('Poppins-Regular.woff2', $font-path)) format('woff2');
  font-weight: $fw-regular;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'poppins';
  src: url(path('Poppins-Medium.woff2', $font-path)) format('woff2');
  font-weight: $fw-medium;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'poppins';
  src: url(path('Poppins-SemiBold.woff2', $font-path)) format('woff2');
  font-weight: $fw-semibold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'poppins';
  src: url(path('Poppins-Bold.woff2', $font-path)) format('woff2');
  font-weight: $fw-bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'poppins';
  src: url(path('Poppins-ExtraBold.woff2', $font-path)) format('woff2');
  font-weight: $fw-extrabold;
  font-style: normal;
  font-display: swap;
}

html {
  box-sizing: border-box;
  font-size: 100%;
}

[body] {
  --rightAside: -10vw;
  --topAside: -2vw;
  --gutterRight: calc(100% * 1/3);
  --headerHeight: 52px; // fallback
  --header-logo-width: 110px;

  @media (min-width: $bp-l) {
    --headerHeight: 116px; // fallback
    --header-logo-width: 178px;
  }

  @media (min-width: #{$layout-width}) {
    --gutterRight: calc(#{$layout-width} * 1/3);
  }

  &.elementOnRightNotPresent {
    --gutterRight: 0;
  }
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  background-color: $c-white;
  color: $c-greynite;
  font: em($default-font-size, 16) $font-stack;
  letter-spacing: .5px;
  padding: 0;
  margin: 0;
  position: relative;

  &::before {
    background-color: $c-whitegrey;
    content: '';
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    transform: translateY(-100%);
    transition: opacity .25s, transform .25s .25s;
    width: 100%;
    z-index: 1;
  }

  &.is-overlayed {
    overflow: hidden;
  }

  &.is-nav-open {
    height: 100vh;
    overflow: hidden;

    &::before {
      opacity: 1;
      transition: opacity .25s;
      transform: translateY(0);
    }
  }
}


a {
  color: $c-greynite;
  text-decoration: none;
}

button {
  appearance: none;
  background: transparent;
  border: 0;
  border-radius: 0;
  cursor: pointer;
  outline: none;
  padding: 0;
}

strong {
  font-size: inherit;
  margin-left: 0;
}

figure {
  margin: 0;

  figcaption {
    font-size: em(12);
  }
}

iframe {
  border: 0;
}

img {
  height: auto;
  max-width: 100%;
}

main {
  position: relative;
  z-index: 0;
}

table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  border: 0;
  font-size: 100%;
  font: inherit;
  margin: 0;
  padding: 0;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input,
textarea,
select {
  border-radius: 0;
}

.sv-g [class*="sv-u"] {
  font-family: $font-stack;
  letter-spacing: .1px;
}

figure {
  display: block;
  position: relative;

  img {
    display: block;
  }

  picture {
    display: block;
  }
}

ul {
  list-style-type: square;
}

ul:not(.l-unlist) {
  --gutter: 20px;
  $gutter: var(--gutter);
  margin-top: 20px;
  margin-bottom: 20px;
  padding-left: $gutter;
  list-style-type: none;

  li {
    line-height: 1.8;
    list-style-type: none;
    display: flex;

    &::before {
      content: '';
      @include background-svg($check, $c-green, 10px, 10px);
      flex-shrink: 0;
      display: inline-block;
      position: relative;
      left: calc(#{$gutter} * -1);
      top: 0;
      margin: 9px -.4rem 0 -4px;
      vertical-align: baseline;
    }

    p {
      margin-top: 0;
    }
  }
}

.block-frame {
  border-radius: $radius;
  height: 148px;
  border: 2px solid $c-whitegrey;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;

  @at-root {
    a#{&} {
      transition: border-color .2s;

      &:hover {
        border-color: $c-greylite;
      }
    }
  }

  picture {
    display: flex;
    max-height: 100%;
    max-width: 100%;
  }

  img {
    display: block;
    object-fit: contain;
  }
}

@media screen and (min-width: $bp-m) {

  body {
    &.is-nav-open {
      height: auto;
      overflow: visible;

      &::before {
        content: none;
      }
    }
  }
}
