/**
 * ==================== @ ARTICLES & TEASER
 */
.articles {

  .tags-filter__wrapper {
    padding-top: 30px;

    .tags-filter-list {
      display: flex;
      flex-wrap: wrap;
      gap: .5rem;

      .tags-filter {
        border: 1px solid $c-greylite;
        border-radius: 2px;
        display: inline-block;

        &.m--active a,
        &:hover a {
          background-color: $c-greynite;
          color: $c-white;
        }
      }

      a {
        color: $c-grey;
        display: block;
        font-size: em(12);
        font-weight: $fw-medium;
        padding: .75rem 1rem;
        text-transform: uppercase;
        transition: background-color 250ms, color 250ms;
      }
    }
  }

  .l-wrapper-frame {
    padding: 12px;
  }

  /* Related
	----------------*/
  .news-related {
    display: flex;
    flex-wrap: wrap;
    padding: 2rem;

    >*:not(div) {
      flex-basis: 100%;
    }
  }

  /* Tutos vidéos
	----------------*/

}

.article {
  background-color: $c-white;
  border-radius: $radius;
  max-width: $layout-width;
  margin: 0 auto;
  padding: 2rem 15px;

  .page-header {
    padding: 0;
  }

  .post-title {
    font-size: em(30);
    font-weight: $fw-medium;
    color: $c-greynite;
  }

  .post-caption {
    font-size: em(15);

    a {
      text-decoration: underline;
      text-decoration-color: $c-green;
      text-decoration-thickness: 2px;
    }
  }

  .post-tag,
  .post-publish {
    padding: .5rem 0;
    font-weight: $fw-medium;
    font-size: em(12);
    border-top: 1px solid $c-greylite;
    border-bottom: 1px solid $c-greylite;
    margin-top: 35px;
    margin-bottom: 15px;
    color: $c-grey-2;
  }

  .post-publish {
    padding-left: 7px;
    display: block;
    font-style: italic;
    position: relative;

    &::before {
      content: '';
      top: 0;
      left: 0;
      height: 50%;
      width: 1px;
      position: absolute;
      background-color: $c-greylite;
    }
  }

  .post-visual img {
    border-radius: $radius;
  }

  .grid-layout .grid-col-content {
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;
  }
}


@media all and (min-width : $bp-m) {
  .articles {
    .l-wrapper-frame {
      padding: 42px;
    }
  }

  .article {
    padding: 2rem;

    .l-wrapper {
      //max-width: 40em;
    }

    .post-title {
      padding-right: 2rem;
    }
  }
}

@media all and (max-width: 767px) {
  .articles.videos {
    .slide-news {
      background-color: transparent;

      .slide-publish {
        display: none;
      }

      .slide-tag {
        margin-left: 0;
        margin-top: 12px;
      }

      .slide-caption {
        padding-left: 0;
      }
    }
  }

  .m--news-inner {
    padding-left: 0;
    padding-right: 0;
  }
}