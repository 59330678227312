.lp {
  --lp-gutter: 1.5rem;

  min-height: 100vh;

  @media all and (min-width: $bp-s) {
    --lp-gutter: 3rem;
  }

  @media (min-width: $bp-1280) {
    display: flex;
  }

  @media all and (min-width: $bp-xl) {
    --lp-gutter: 4rem;
  }

  h1,
  h2,
  h3 {
    margin-block: 0;

    span {
      font-weight: 800;
    }
  }

  h1 {
    @extend h2;
  }

  h2 {
    margin-top: 1.25rem;
  }

  img {
    display: block;
  }
}

.lp__main {
  background-color: $c-white;

  @media (min-width: $bp-1280) {
    flex-grow: 999;
    flex-basis: 0;
  }
}

.lp__aside {
  position: sticky;
  bottom: 0;
  z-index: 100;

  @media (min-width: $bp-1280) {
    flex-grow: 1;
    flex-basis: 40%;
    top: 0;
    max-width: 40rem;
    height: 100vh;
    padding: 3rem;
    background-color: $c-whitegrey;
    overflow: auto;
    overscroll-behavior: contain;
  }
}

.lp-section {
  max-width: 80rem;
  margin-inline: auto;
  padding-inline: var(--lp-gutter);
}

.lp-badge {
  position: fixed;
  right: 0;
  bottom: 3rem;

  @media not all and (min-width: $bp-1280) {
    display: none;
  }
}
