/**
 * ==================== @ CTA
 */

.cta-wrapper-desktop {
  max-width: 82em;
  margin: 0 auto;
  width: 100%;
}

.cta {
  width: 100%;
  //display: flex;
  position: fixed;
  overflow: hidden;
  bottom: 0;
  left: 0;
  z-index: 10;

  .cta-inner {
    max-width: calc(960px - 70px);
    width: 100%;
    margin: 0 auto;
  }

  .cta-wrap {
    background-color: $c-white;
    border: 3px solid $c-white;
    border-radius: 10px 10px 0 0;
    margin: 0 auto;
    overflow: hidden;
  }

  h3,
  h4 {
    font-size: em(14);
    font-weight: $fw-bold;
    margin: 0;
    text-align: center;
  }

  h3 {
    background-color: $c-greylite;
    padding: .5em 0;
    display: none;
  }

  .cta-items {

    a {
      background-color: $c-green;
      padding: 15px 10px;
      color: $c-white;
      display: flex;
      align-items: center;
      cursor: pointer;
      transition: background-color 300ms ease;

      &:hover {
        background-color: $c-green-hover;

        .svg-icon {
          transform: scale(0.8);
        }

      }

      .svg-icon {
        height: 20px;
        color: $c-white;
        transition: 300ms ease;
      }

      h4 {
        width: calc(100% - 20px);
        font-size: rem(10px);
        text-align: left;
        padding-left: 5px;
      }


      &:first-child {
        background-color: $c-black;

        &:hover {
          background-color: $c-black-hover;
        }

        .svg-icon {
          font-size: em(32);
        }
      }

      &:last-child {
        color: $c-black;
        background-color: $c-yellow;

        &:hover {
          background-color: $c-yellow-hover;
        }

        .svg-icon {
          color: $c-black;
        }
      }
    }
  }

  h4 {
    margin: auto;

    .svg-icon {
      color: $c-white;
      font-size: em(24);
      margin-bottom: .5rem;
    }
  }
}

@media screen and (min-width:$bp-s) {
  .cta {

    .cta-items {

      a {

        h4 {
          font-size: rem(16);
        }
      }
    }
  }
}


@media screen and (min-width:$bp-m) {
  .cta {

    .cta-items {

      a {
        padding: 20px 15px;

        h4 {
          font-size: rem(15);
          padding-left: 15px;
        }

        .svg-icon {
          font-size: rem(20);
          height: 35px;
        }
      }
    }
  }
}


@media screen and (min-width:$bp-1280) {

  .cta {
    position: fixed;
    top: 226px;
    left: calc((100vw - 78em) /2);
    width: 125px;

    .cta-wrap {
      border-radius: 10px;
    }

    h3 {
      display: block;
    }

    .cta-items {
      a {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        h4 {
          text-align: center;
          margin: 0;
          margin-top: 5px;
          padding: 0;
          font-size: rem(13);
          line-height: 1.2;
          width: 100%;
        }
      }
    }
  }

  .eqco2 {
    bottom: 40px;

    .eqco2__icon {
      line-height: 90px;
      height: 76px;
      width: 76px;

      .svg-icon {
        color: #fff;
        font-size: em(36);
      }
    }

    .eqco2__value {
      height: 54px;
    }
  }
}


/*@media screen and (min-width:90em) {

	.eqco2 {
		display: block;
	}
}*/