.lp-footer {
  padding-block: 2rem;
  background-color: $c-whitegrey;

  > * + * {
    margin-top: 2rem;
  }

  @media (min-width: $bp-s) {
    padding-block: 1.5rem;

    > * + * {
      margin-top: 1.5rem;
    }
  }
}

.lp-footer__links {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  gap: 1rem 1.5rem;

  @extend .l-unlist;

  li {
    display: inline-flex;
  }

  a:not(.btn) {
    font-size: 0.9375rem;
    color: $c-greydark;
  }
}
