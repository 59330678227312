/**
 * ==================== @ THEME VARIABLES
 */

// --- Breakpoints
$bp-s: 40em; // 640px
$bp-m: 48em; // 768px
$bp-l: 64em; // 1024px
$bp-lx: 72em; // 1152px
$bp-1280: 80em; // 1280px
$bp-xl: 100em; // 1600px
$max-s: 639px;
$max-large: 1279px;

$gutter: 20px;

// --- Paths
$font-path: '/assets/fonts/';
$img-path: '/assets/images/';

// --- Fonts
$icons-fontname: 'icons';
$default-font-size: 16px;
$font-stack: 'poppins', Helvetica, Arial, sans-serif;
$font-italic: 'overpassit', Helvetica, Arial, sans-serif;
$font-baseline: 'ccsignlanguage', cursive;

$fw-light: 300;
$fw-regular: 400;
$fw-medium: 500;
$fw-semibold: 600;
$fw-bold: 700;
$fw-extrabold: 800;
$fw-black: 900;

// --- Colors
$c-white: #ffffff;
$c-white2: #FCFDF5;
$c-whitegreen: #F8FAEB;
$c-whitegrey: #F4F5F6;
$c-greenpastel: #F1F6D8;
$c-greenlite: #DBE79D;
$c-green: #B8CF3C;
$c-greendark: #7FAE3B;
$c-greylite: #E9EBED;
$c-grey: #929DA6;
$c-greydark: #516371;
$c-greynite: #263C4E;

$c-black: #111111;
$c-black-hover: #020202;

$c-red: #F70004;
$c-grey: #656566;
$c-greymid: #DBDBDB;
$c-greylite-2: #ECEFF2;
$c-green-hover: #028930;
$c-yellow: #FFDA40;
$c-yellow-hover: #e3c33d;
$c-sand: #FBFBFB;
$c-input: #F6F5FA;
$c-label: #8F919D;
$c-grey-2: #929DA6;

// --- Layout vars
$radius: 5px;
$radius-10: 10px;
$layout-padding: $gutter;
$layout-width: 1300px;
$grid-offset: 8.3333%;
$grid-offset-2: 16.666%;
$grid-outset: calc(8.3333% - 2rem);
$cols-padding: 1rem;
$cols-padding-m: 2rem;
$section-padtop: 4rem;

$bpLayout: 1450px;
$gutterLeft: 7.5%;
$gutterRight: var(--gutterRight);
$sizeCircle: calc((.42 * #{$layout-width}) + ((100vw - #{$layout-width}) / 2));

// --- SVG
$svg-pen: '<svg viewBox="0 0 38 32"><path d="M1.69 1.7A5.61 5.61 0 0 1 5.07.04h.02a4.03 4.03 0 0 1 3.33 1.11l21 21c.1.1.17.22.2.34l2.27 8.53a.75.75 0 0 1-.72.97.74.74 0 0 1-.2 0l-8.52-2.3a.68.68 0 0 1-.34-.2L1.14 8.43a4.8 4.8 0 0 1 .54-6.73zm25.13 24.07a5.69 5.69 0 0 0 1.27-2.76v-.04L8.34 3.22A7.8 7.8 0 0 1 6.9 5.85v-.01zm2-.32a8.52 8.52 0 0 1-3.38 3.4l-.05.03 4.7 1.27zm-5.9 2.67a5.66 5.66 0 0 0 2.81-1.28L5.81 6.94a7.76 7.76 0 0 1-2.56 1.43l-.06.02zM2 7.1a6.58 6.58 0 0 0 5.07-5.04L7.08 2a2.6 2.6 0 0 0-1.86-.47h.01c-.98.11-1.84.56-2.48 1.23A3.41 3.41 0 0 0 2 7.1z"></path></svg>';
$svg-right: '<svg viewBox="0 0 38 32"><path d="M4.776 0.495c-0.259-0.294-0.637-0.479-1.058-0.479s-0.798 0.185-1.056 0.478l-0.001 0.002-2.115 2.289c-0.273 0.302-0.44 0.704-0.44 1.145s0.167 0.843 0.441 1.146l-0.001-0.001 10.196 10.89-10.281 10.983c-0.273 0.302-0.44 0.704-0.44 1.145s0.167 0.843 0.441 1.146l-0.001-0.001 2.115 2.289c0.261 0.29 0.638 0.472 1.058 0.472s0.796-0.182 1.056-0.471l0.001-0.001 13.46-14.417c0.27-0.303 0.435-0.704 0.435-1.145s-0.165-0.842-0.437-1.146l0.002 0.002z"></path></svg>';
$svg-arrow-left: '<svg viewBox="0 0 20 32"><path d="M0.014 15.713c0.082-0.67 0.382-1.259 0.827-1.703v0l12.522-12.875c0.607-0.643 1.443-1.064 2.377-1.132l0.012-0.001c0.101-0.010 0.219-0.015 0.338-0.015 0.846 0 1.628 0.277 2.259 0.744l-0.010-0.007c0.677 0.494 1.132 1.256 1.209 2.127l0.001 0.011c0.003 0.050 0.005 0.108 0.005 0.167 0 0.823-0.349 1.565-0.907 2.086l-0.002 0.002-10.588 10.883 10.588 10.871c0.56 0.522 0.909 1.264 0.909 2.088 0 0.059-0.002 0.117-0.005 0.175l0-0.008c-0.077 0.882-0.533 1.645-1.201 2.133l-0.008 0.006c-0.625 0.468-1.412 0.749-2.266 0.749-0.113 0-0.225-0.005-0.336-0.015l0.014 0.001c-0.946-0.069-1.782-0.49-2.388-1.131l-0.002-0.002-12.522-12.878c-0.518-0.513-0.839-1.225-0.839-2.011 0-0.093 0.004-0.185 0.013-0.276l-0.001 0.012z"></path></svg>';
$svg-arrow: '<svg viewBox="0 0 32 32"><path d="M26.581 15.652c-0.081-0.667-0.38-1.253-0.821-1.696l0 0-12.476-12.8c-0.605-0.642-1.439-1.062-2.37-1.13l-0.012-0.001c-0.104-0.010-0.225-0.016-0.348-0.016-0.838 0-1.613 0.273-2.24 0.734l0.010-0.007c-0.676 0.49-1.132 1.248-1.212 2.115l-0.001 0.011c-0.003 0.049-0.005 0.106-0.005 0.164 0 0.82 0.347 1.559 0.903 2.079l0.002 0.002 10.556 10.834-10.549 10.83c-0.561 0.521-0.911 1.263-0.911 2.086 0 0.055 0.002 0.11 0.005 0.165l-0-0.008c0.076 0.88 0.53 1.641 1.197 2.128l0.008 0.006c0.617 0.454 1.392 0.727 2.23 0.727 0.122 0 0.244-0.006 0.363-0.017l-0.015 0.001c0.943-0.068 1.777-0.489 2.381-1.129l0.002-0.002 12.476-12.8c0.515-0.511 0.834-1.219 0.834-2.002 0-0.094-0.005-0.187-0.014-0.278l0.001 0.012z"></path></svg>';

$svg-quotes: '<svg viewBox="0 0 40 32"><path d="M15.533 0.234c1.407 4.083 2.219 8.787 2.219 13.682 0 0.035-0 0.069-0 0.104v-0.005q0 8.058-4.146 12.905t-12.087 4.845v-7.473q6.073 0 6.073-7.824v-1.869h-7.357v-14.364zM37.605 0.234c1.482 4.095 2.374 8.821 2.452 13.745l0 0.035q0 8.058-4.204 12.905t-12.262 4.845v-7.473q6.191 0 6.191-7.824v-1.869h-7.476v-14.364z"></path></svg>';
$circle: '<svg viewBox="0 0 1154 1155"><g id="img" transform="translate(-6 176)"><ellipse id="Ellipse_209" data-name="Ellipse 209" cx="577" cy="577.5" rx="577" ry="577.5" transform="translate(6 -176)" fill="#b8cf3c"/><g id="Ellipse_209-2" data-name="Ellipse 209" transform="translate(62 -119)" fill="none"><path d="M520.792,0c287.626,0,520.792,232.935,520.792,520.275S808.418,1040.55,520.792,1040.55,0,807.615,0,520.275,233.167,0,520.792,0Z" stroke="none"/><path d="M 520.79248046875 60.00006103515625 C 266.7105102539062 60.00006103515625 59.9998779296875 266.4784545898438 59.9998779296875 520.2748413085938 C 59.9998779296875 774.0712280273438 266.7105102539062 980.5496215820312 520.79248046875 980.5496215820312 C 774.874267578125 980.5496215820312 981.5848999023438 774.0712280273438 981.5848999023438 520.2748413085938 C 981.5848999023438 266.4784545898438 774.874267578125 60.00006103515625 520.79248046875 60.00006103515625 M 520.79248046875 0 C 808.4182739257812 0 1041.584838867188 232.93505859375 1041.584838867188 520.2748413085938 C 1041.584838867188 807.6146240234375 808.4182739257812 1040.549682617188 520.79248046875 1040.549682617188 C 233.1666870117188 1040.549682617188 -0.0001220703125 807.6146240234375 -0.0001220703125 520.2748413085938 C -0.0001220703125 232.93505859375 233.1666870117188 0 520.79248046875 0 Z" stroke="none" fill="#fff"/></g></g></svg>';
$check: '<svg viewBox="0 0 32 32"><path d="M27.942 7.301c-0.525 0.010-1 0.218-1.355 0.551l0.001-0.001-14.527 13.539-7.281-5.246c-0.335-0.241-0.754-0.385-1.207-0.385-0.268 0-0.524 0.050-0.759 0.142l0.014-0.005c-0.629 0.232-1.092 0.776-1.204 1.44l-0.002 0.011c-0.010 0.072-0.016 0.155-0.016 0.24 0 0.602 0.299 1.134 0.756 1.456l0.006 0.004 8.66 6.232c0.335 0.244 0.754 0.39 1.208 0.39 0.040 0 0.080-0.001 0.119-0.003l-0.005 0c0.492-0.026 0.933-0.223 1.269-0.532l-0.002 0.001 15.743-14.68c0.369-0.323 0.603-0.792 0.61-1.316v-0.001c-0.003-0.524-0.233-0.994-0.596-1.318l-0.002-0.002c-0.36-0.322-0.838-0.519-1.363-0.519-0.025 0-0.050 0-0.074 0.001l0.004-0z"></path><path d="M29.016 6.201v0.193c0.416 0.143 0.774 0.359 1.077 0.634l-0.003-0.002c0.583 0.522 0.95 1.275 0.956 2.113v0.001c-0.007 0.837-0.371 1.587-0.947 2.106l-0.003 0.002-15.741 14.673c-0.511 0.472-1.183 0.775-1.926 0.818l-0.008 0h-0.183c-0 0-0 0-0.001 0-0.688 0-1.325-0.221-1.844-0.595l0.009 0.006-8.662-6.234c-0.735-0.523-1.208-1.372-1.208-2.331 0-0.141 0.010-0.28 0.030-0.415l-0.002 0.015c0.173-1.065 0.898-1.927 1.867-2.293l0.020-0.007c0.334-0.131 0.721-0.207 1.125-0.207 0.689 0 1.326 0.22 1.845 0.594l-0.009-0.006 6.565 4.728 13.876-12.926c0.539-0.508 1.262-0.826 2.060-0.842l0.003-0zM12.235 24.596h0.060c0.232-0.010 0.441-0.101 0.6-0.246l-0.001 0.001 15.754-14.686c0.151-0.127 0.246-0.315 0.249-0.526v-0.001c-0.003-0.208-0.096-0.393-0.242-0.519l-0.001-0.001c-0.171-0.15-0.395-0.241-0.642-0.241-0.001 0-0.002 0-0.003 0h-0.060c-0.237 0.011-0.449 0.105-0.611 0.254l0.001-0.001-15.191 14.153-7.998-5.768c-0.163-0.113-0.364-0.18-0.581-0.18-0.128 0-0.251 0.024-0.365 0.067l0.007-0.002c-0.269 0.093-0.469 0.319-0.524 0.597l-0.001 0.005c-0.003 0.025-0.005 0.055-0.005 0.084 0 0.245 0.125 0.46 0.314 0.587l0.003 0.002 8.658 6.232c0.16 0.117 0.361 0.188 0.578 0.189h0z"></path></svg>';
