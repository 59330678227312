.form-group {
	.form-row {
		position: relative;
		margin-bottom: 15px;
		//display: flex;

		&:last-child {
			margin-bottom: 0;
		}

		.form-label {
			//position: absolute;
			//top: 18px;
			//left: 18px;
			//transition: 300ms ease;
			color: $c-label;
			pointer-events: none;
		}

		/*.form-tips {
			position: absolute;
			top: 30px;
			left: 18px;
			color: $c-label;
			pointer-events: none;
			font-family: $font-italic;
			width: calc(100% - 36px);
			font-size: rem(14);
		}*/

		.form-input[type=text], .form-input[type=email], textarea.form-input {
			border: 0;
			outline: 0;
			padding: 18px;
			background-color: $c-input;
			width: 100%;
			transition: 300ms ease;
			resize: none;
			color: $c-label;
			font-size: rem(14);
			height: max-content;
		}

		/*&.m--focus{
			.form-input {
				margin-top: 25px;
			}

			.form-label {
				top: 0;
				left: 0;
			}

			.form-tips {
				display: none;
			}
		}*/
		
		.form-checkbox {
			margin-right: 10px;
			opacity: 0;
			position: relative;
			cursor: pointer;

			&:checked {
				+ label {
					&:after {
						content: "✓";
						height: 14px;
						width: 14px;
						position: absolute;
						top: 0;
						left: 0;
						display: flex;
						justify-content: center;
						align-items: center;
						font-size: rem(10);
				
					}
				}

			}

			+ label {
				&:before {
					content: "";
					height: 14px;
					width: 14px;
					border-radius: 3px;
					border: 1px solid #b7b7b7;
					position: absolute;
					top: 0;
					left: 0;
				}
			}

		}
		.label-checkbox {
			position: static;
			padding: 0;
			line-height: 1.3;
			pointer-events: all;
			cursor: pointer;
			display: inline-block;
		}

		&.m--center {
			display: flex;
			justify-content: center; 
		}
	
	}

	.form-submit {
		margin-top: 50px;
		width: 100%;
	}
}


/**
 * ==================== @ GENERIC FORMS
*/

.sv-form {
	
	fieldset {
		border: none;
		padding: 0;
	}
	
	legend {
		font-size: em(26);
	}
	
}

.form-field {
	padding: .5em;
	margin-bottom: 2rem; 
	
	&.m--action {
		text-align: center;
	}
}

.form-label {
	display: block;
	font-size: em(13);
	padding: .2em 0;
	
	&.required:after {
		content: '*';
		margin-left: .5em;
		color: $c-red;
	}
}

.f_error {
	color: $c-red;
	display: block;
	font-size: em(13);
}

.success {
	color: #539300;
	font-size: em(24);
}

.form-field__input, .form-field__select, .form-field__text {
	border: none;
	border-bottom: 1px solid $c-grey;
	color: $c-black;
	font-size: em(16);
	padding: .5em;
	width: 100%;
}

.form-field__text {
	min-height: 10rem;	
}

.form-field__submit {
	background-color: $c-red;
	border-radius: 20px;
	color: #fff;
	font-size: em(14);
	padding: .5em 1.5em;
	text-transform: uppercase;
}

@media screen and (min-width:$bp-l) {
	.form-group {
		margin-top: 40px;
	}
}
