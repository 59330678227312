.entry {
  color: $c-greynite;

  // p:first-of-type {
  //   &::before {
  //     content: '';
  //     height: 2px;
  //     background-color: $c-greylite;
  //     display: block;
  //     margin-top: 30px;
  //     margin-bottom: 12px;
  //   }
  // }

  p {
    color: $c-greynite;
  }

  hr {
    all: unset;
    display: block;
    height: 2px;
    background-color: $c-greylite;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  strong {
    font-size: inherit;
    margin-left: 0;
  }

  a {
    text-decoration: underline;
    text-decoration-color: $c-green;
    text-decoration-thickness: 2px;
  }

  .blockquote {
    margin: 0;
    font-size: rem(20);
    line-height: lh(36, 20);
    padding-top: 96px;
    padding-bottom: 98px;
    padding: 96px 30px 50px;
    text-align: center;
    z-index: 0;
    background-color: $c-greylite;
    border-radius: $radius-10;
    text-align: left;

    >*:first-child {
      position: relative;
      z-index: 1;

      &::before {
        @include background-svg($svg-quotes, rgba($c-green, .4));
        content: '';
        height: 70px;
        left: calc(50%);
        transform: translate(-50%, -55%);
        position: absolute;
        top: 0;
        width: 80px;
        z-index: -1;
      }
    }

    &__text {
      text-align: center;

      &:first-child {
        margin-top: 0;
      }

      &,
      * {
        font-size: 20px;
      }
    }

    &__footer {
      margin-top: 24px;
      color: $c-grey;
      font-size: rem(15);
      text-align: right;
    }
  }

  @media (min-width: $bp-l) {
    .blockquote {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.section-seo {
  border-radius: $radius;

  p {
    margin-top: 0 !important;
  }

  .grid-layout .grid-col-content {
    padding: 24px;
  }

  .entry,
  p,
  a:not(.btn),
  h1,
  h2,
  h3,
  h4,
  h5 {
    color: $c-white;
  }

  @media (min-width: $bp-lx) {
    .entry {
      display: flex;
      align-items: center;
      justify-content: space-between;
      column-gap: 20px;
    }

    .grid-layout .grid-col-content {
      padding-left: 48px;
      padding-right: 48px;
    }

    .section-seo__2 {
      flex-shrink: 0;
    }

    .btn-wrapper {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}

h1,
h2,
h3,
h4,
h5 {
  color: $c-greynite;
  margin: .5rem 0 1rem 0;
}

h1 {
  color: $c-green;
  font-size: rem(30);
  line-height: 1.18;
  font-weight: $fw-medium;

  &.m--404 {
    font-size: em(44);
  }
}

h2 {
  font-size: rem(28);
  line-height: 1.25;
  font-weight: $fw-semibold;
}

h3 {
  font-size: rem(30);
  line-height: 1.25;
  font-weight: $fw-semibold;
  margin: .5rem 0;
}

h2 {
  position: relative;
  margin-top: 2rem;

  &::before {
    content: '';
    height: 0.5rem;
    width: 3.125rem;
    background-color: $c-green;
    position: absolute;
    left: 0;
    top: -1.25rem;
  }

  &.h2-fixed {
    margin-top: 1.25rem;
    margin-bottom: 0;
  }
}

h4 {
  font-size: rem(20);
  line-height: 1.375;
  font-weight: $fw-medium;
}

p {
  color: $c-greydark;
  font-size: rem(15);
  line-height: 1.5;

  &:not(:only-of-type, :last-of-type, :empty) {
    margin: .5rem 0 1rem 0;
  }

  &:only-of-type {
    margin-bottom: 0;
  }
}

p:empty {
  all: unset;
}

.text {
  p {
    margin-top: 1rem;
    margin-bottom: 0;

    &:first-of-type {
      margin-top: 0;
    }
  }

  strong {
    color: $c-greynite;
  }

  @media (min-width: $bp-s) {
    p {
      margin-top: 1.5rem;
    }
  }
}

@media all and (min-width : $bp-m) {

  h1 {
    font-size: rem(55);
    line-height: 1.09;

    &.m--404 {
      font-size: rem(80);
    }
  }

  h2 {
    font-size: rem(32);
  }

  h3 {
    font-size: rem(20);
    line-height: 1.25;
  }

  h4 {
    font-size: rem(20);
    line-height: 1.1;
  }
}

@media all and (max-width : 767px) {
  .entry p {
    margin-top: 30px;
  }
}
