.block-cards {
  &.pg__section {
    margin-top: 58px;
    margin-bottom: 28px;
  }

  .grid-layout .grid-col-content {
    padding: 0;
  }

  &__grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px 14px;
  }

  @media (min-width: $bp-m) {
    &__grid {
      &_2 {
        max-width: 630px;
      }

      &_3 {
        grid-template-columns: 1fr 1fr 1fr;
        gap: 20px;
      }
    }
  }
}