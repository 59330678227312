.section-netreviews__wrapper {
  position: relative;
  height: 100%;
  margin-bottom: -8px;
  .netreviews__wrapper-bg-left {
    background-image:url(path('/netreviews_left.png'));
    background-position: top;
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    height: 100%;
    width: 100%;
    img {
      height: 100%;
    }
  }
  .netreviews__wrapper-bg-right {
    background-image:url(path('/netreviews_right.png'));
    background-position: bottom;
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    height: 100%;
    width: 100%;
    img {
      height: 100%;
    }
  }
}

@media screen and (min-width:$bp-m) {
  .section-netreviews {
    .section-netreviews__right {
      .swiper-button-prev {
        left: 0;
      }
    }
  }
  .section-netreviews__wrapper {
    .netreviews__wrapper-bg-left {
      background-position: left;
    }
    .netreviews__wrapper-bg-right {
      background-position: right;
    }
  }
} 

.section-netreviews {
  padding: 3rem;
    .l-wrapper {
      background-color: white;
      border-radius: 5px;
      padding: 2rem;
    }
    .section-netreviews__container {
      padding: 2rem 0;
      position: relative;

      img.logo {
        width: 160px;
      }

      .section-netreviews__left {
        .note {
          font-weight: 600;
          margin-left: 10px;
        }
        .stars {
          align-items: center;
          display: flex;
          margin-top: 1rem;
          .star, .star_half {
            color: $c-green;
            margin: 0 4px;
          }
          .star:first-child {
            margin-left: 0; 
          }
        }
        .total {
          margin-top: -1.5rem;
        }
        span {
          font-weight: 600;
        }
      }

      .section-netreviews__right {
        position: relative;
        .swiper {
          width: 90%;
          margin: auto;
        }
        .swiper-button-prev {
          position: absolute;
          left: -20px;
        }
        
        .swiper-button-prev ,
        .swiper-button-next 
        {
          border-radius: 50%;
          flex-shrink: 0;
          height: 33px;
          width: 33px;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          background-color: $c-greynite;
          border: 4px solid #929DA6;
          color: white;
          transition: background-color 250ms, border-color 250ms;

          &::after {
            background-color: $c-greynite;
            font-family: swiper-icons;
            font-size: 0.7rem;
            margin-top: -28%;
            position: absolute;
            top: 50%;
          }
        }
        .swiper-slide {
          .swiper-slide__header {
            .slide__header--name {
              font-weight: 600;
              margin-bottom: -1rem;
            }
            .slide__header--info {
              font-size: 0.8rem;
            }
            .slide__header--stars {
              align-items: center;
              display: flex;
              margin-top: 1rem;
              .star, .star_half {
                color: $c-green;
                margin: 0 4px;
              }
              .star:first-child {
                margin-left: 0; 
              }
            }
          }
        }
      }
    }
    
  }
