.navigation-link {
  display: flex;
  align-items: center;
  min-height: 42px;
  padding-block: 0.5rem;
  padding-inline: 1.5rem;
  border-radius: 0.5rem;
  background-color: $c-whitegrey;
  transition-property: background-color;
  transition-duration: 150ms;

  @media (min-width: $bp-l) {
    padding-block: 0.75rem;
  }

  &:hover,
  &:focus-visible {
    background-color: $c-green;
  }
}

.navigation-link__icon {
  flex-shrink: 0;
  display: inline-flex;
  align-items: center;
  width: 4rem;
  height: 2.25rem;

  &--rectangular {
    font-size: 1.875rem;
  }

  &--square {
    font-size: 2.25rem;
  }
}

.navigation-link__label {
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.25rem;

  @media (min-width: $bp-s) {
    font-size: 1rem;
  }
}
