
.contact-infos {
	h2 {
		margin-bottom: 0!important;
	}
}

.contact-info {
	display: flex;
	
	&:not(:last-child) {
		margin-bottom: 3rem;
	}

	.svg-icon {
		margin-right: 15px;
		font-size: rem(20);
		
		&.m--bigger {
			font-size: rem(30);
		}
	}

	p {
		margin: 0;
		font-weight: $fw-bold;
	}
    
}



.contact-info-cta {
	display: block !important;
	margin-top: -2rem !important;
	text-decoration: underline;
	font-weight: $fw-regular;
	margin-left: 2.8rem;
}

.contact-wrapper {
	&:not(:last-child) {
		margin-bottom: 3rem;
	}
}

.contact-horaires {
	margin-top: 2rem;
	font-weight: $fw-bold;
	display: flex;
	flex-wrap: wrap;

	.contact-horaire {
		width: 100%;
		display: flex;
		flex-wrap: wrap;

		.horaires-jour {
			margin-bottom: .5rem;
		}

		.horaires-heures {
			.heures-debut {
				margin-right: 1rem;
			}
		}

		&:not(:last-child) {
			margin-bottom: 1.5rem;
		}
	}

	.horaires-jour {
		width: 100%;
	}
}


.contact-inner-padding {
	padding: 2.5rem 1rem;
}

.contact-banner {

	&.m--yellow {
		background-color: $c-yellow;
	}

	.contact-inner-padding {
		padding: 1.5rem;
	}

	.contact-banner-row {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		text-align: center;
		justify-content: center;

		h2 {
			font-size: rem(20);
			width: 100%;
		}
	}
}



@media all and(min-width : $bp-m) {
	.contact-banner {
		margin-top: 25px;

		.contact-inner-padding {
			padding: 1.25rem 2rem;
		}
	}

	.contact-inner-padding {
		padding: 3rem 1rem;
	}

	.contact-form {
		margin-top: 30px;

		form {
			margin-top: 15px;
		}
	}

	.contact-banner {
		text-align: inherit;
		
		.contact-banner-row {
			flex-wrap: nowrap;
			justify-content: inherit;
			
			h2 {
				margin-right: 50px;
				width: inherit;
			}
		}
	}

	.contact-wrapper:not(:last-child) {
		margin: 0;
	}

	.contact-banner {
		margin-top: 0;
	}
}


@media all and(min-width : $bp-l) {
	.contact-inner-padding {
		padding: 1.75rem 2rem;
	}

	.contact-horaires {

		.contact-horaire {

			&:not(:last-child) {
				margin-bottom: .75rem;
			}

			.horaires-jour {
				margin: 0 15px 0 0;
				min-width: 150px;
				width: max-content;
			}
		}
	}
}
