/**
 * ==================== @ GENERIC / PAGES
 */

.page-header {
  background-color: $c-white;
  max-width: $layout-width;
  margin: 0 auto;
  padding: 30px $gutter 35px $gutter;
  width: 100%;

  &.m--center {
    text-align: center;
  }

  // @at-root body.page & {

  //   &__title,
  //   &__caption {
  //     max-width: 70%;
  //   }
  // }


  @media (min-width: $bp-lx) {
    &__title {
      max-width: none;
    }
  }
}

.page-header__caption {
  font-size: em(15);
}

/**
 * ==================== @ ALL PAGES
 */

.wrapper-edito,
.l-wrapper-frame {
  // padding: 0;
  // max-width: $layout-width;
  // margin: -10px auto 0 auto;
  // padding: 1px 8px;

  .pg__section {
    border-radius: $radius-10;
  }
}

.pg__section {
  // background-color: $c-white;
  margin: .5rem auto;
  max-width: $layout-width;
  padding-left: $layout-padding;
  padding-right: $layout-padding;
  overflow: hidden;

  @at-root .section-seo,
  &.dark {
    background-color: $c-greynite;
  }

  /* snippet */
  &.iframe {
    // background-color: $c-white;
    min-height: 100px;
    position: relative;
    z-index: 4;

    >iframe {
      width: 1px;
      min-width: 100%;
    }
  }

  // &.table {
  //   padding: 2rem 8px 30px;
  // }
}

/* === 768px === */
@media screen and (min-width:$bp-m) {

  // .wrapper-edito,
  // .l-wrapper-frame {
  //   padding: 12px 20px;
  // }

  .pg__section {
    border-radius: $radius-10;

    &.table {
      padding: 3rem 5rem;
    }
  }
}

/* === 1024px === */
@media screen and (min-width:$bp-l) {

  .page-header__caption {
    .error & {
      padding-right: 0;
    }
  }
}