.edito-extend {
    
    // .grid-col-img {
    //     padding-top: 2.4rem;
    // }

    .grid-col-img {
        padding-top: 30px;
        padding-bottom: 30px;
        max-width: 70%;
        margin-left: auto;
        margin-right: auto;
    }
    
    @media (min-width: $bp-m) {
        .layout-txt-img .entry {
            padding: .5rem 2rem .5rem 0;
        }
        
        .layout-img-txt .entry {
            padding: .5rem 2rem .5rem 0;
        }
        
        .layout-img-txt .grid-col-img {
            padding-right: 2rem;
        }
    
        .edito-section {
            margin: 0 1rem 1rem;
            padding: 40px 30px 40px 4rem;
        }
    
        .layout-txt-img .grid-col-img {
            padding-left: 2rem;
        }
        
        .grid-col-img {
            padding-top: 0;
            padding-bottom: 0;
            max-width: unset;
            margin-left: unset;
            margin-right: unset;
        }
    }
}