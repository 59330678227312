.call-to-action {
  $propagation: 8px;
  display: none;
  z-index: 3;
  position: relative;
  top: var(--headerHeight);
  padding-top: calc((#{$sizeCircle}) - 20vw);
  position: absolute;
  width: calc(#{$gutterRight} - #{$propagation * 2});
  max-width: 315px;
  right: $propagation;
  height: var(--lwrapperframeHeight);

  &__block {
    position: sticky;
    top: calc(var(--headerHeight) + 24px);
  }

  &__frame {
    // margin: calc(var(--topAside) - 9vw) auto $propagation;
    max-width: 100%;
    background-color: $c-whitegrey;
    border-radius: $radius-10;
    padding: 32px;
    box-shadow: $propagation $propagation 0px 0px $c-green;
  }

  &__title {
    all: unset;
    display: block;
    font-size: rem(20);
    line-height: 1.2;
    padding-bottom: .8em;

    &::before,
    &::after {
      content: none;
    }
  }

  &__item {
    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }

  &__link {
    padding-right: rem(8);
  }

  @media (min-width: $layout-width) {
    display: block;
  }

  @media (min-width: calc(#{$layout-width + $propagation})) {
    right: calc((100% - #{$layout-width}) / 2 + var(--tabbarWidth, 0px));
    width: calc(#{$layout-width} / 4);
  }
}

@media (max-width: $layout-width) {
  .call-to-action__responsive {
    max-width: 90%;
    background-color: #f4f5f6;
    border-radius: 10px;
    padding: 32px;
    box-shadow: 8px 8px 0 0 #b8cf3c;
    margin: 3rem auto;
  }
}
@media (min-width: $layout-width) {
  .call-to-action__responsive {
    display: none;
  }
}

