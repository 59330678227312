.lp-features {
  padding-block: 3rem;
  background-color: $c-green;

  @media (min-width: $bp-xl) {
    padding-block: 4rem;
  }

  h2::before {
    background-color: $c-greynite;
  }

  h3 {
    font-size: 1.625rem;
  }
}

.lp-features__grid {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;

  @media (min-width: $bp-s) {
    gap: 3rem;
  }
}

.lp-feature-item {
  flex-basis: 0;
  flex-grow: 1;
  min-width: calc(min(14rem, 100%));
}

.lp-feature-item__icon {
  display: inline-flex;
  margin-bottom: 0.75rem;
  font-size: 64px;
}
