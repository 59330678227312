/**
 * ==================== @ Figures and pattern BG
 */

figure {

  &.img-radius {

    picture {
      border-radius: $radius;
      display: block;
      overflow: hidden;
    }

    img {
      width: 100%;
    }
  }

  &.img-rounded {
    display: block;
    position: relative;

    &::after {
      content: '';
      background: url(path('layout/circle4.svg')) 50% -8% no-repeat;
      background-size: 180%;
      position: absolute;
      inset: 0;
      z-index: 2;
      transform: scaleY(-1);
    }

    .mask-mobile,
    .mask-desk {
      position: absolute;
      z-index: 2;
    }

    .mask-mobile {
      inset: auto 0 -1px 0;
      width: 100%;
    }

    .mask-desk {
      display: none;
    }
  }

  &.thumb-video picture {
    border-radius: $radius;

    overflow: hidden;
    position: relative;
    z-index: 1;

    &::before {
      content: '';
      background-color: rgba($c-greynite, .5);
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 2;
    }
  }
}

@media all and (min-width : $bp-m) {
  figure {
    &.img-rounded {
      &::after {
        transform: scaleY(1);
        background-position: -15% center;
        background-size: 145%;
        width: 100%;
      }

      .mask-mobile {
        display: none;
      }

      .mask-desk {
        display: block;
        height: 101%;
      }
    }

    &.img-radius picture {
      border-radius: $radius-10;
    }
  }
}