/**
 * ==================== @ ICONS
 */

.svg-icon {
  color: $c-greydark;
  display: inline-block;
  fill: currentColor;
  height: 1em;
  width: 1em;
  stroke-width: 0;
  stroke: currentColor;
  vertical-align: -0.2em; // https://fvsch.com/code/svg-icons/how-to/#section-styling

  &.arrow-left {
    width: 0.611328125em;
  }

  &.location {
    width: 1.07421875em;
  }

  &.piggy-bank {
    width: 1.07421875em;
  }

  &.ecology {
    width: 1.07421875em;
  }

  &.comfort {
    width: 1.07421875em;
  }

  &.map-url {
    width: 1.1025390625em;
  }

  &.heating {
    width: 1.71875em;
  }

  &.heat-pump-air-water {
    width: 1.75em;
  }

  &.heat-pump-air-air {
    width: 1.75em;
  }

  &.heat-pump-hybrid {
    width: 1.46875em;
  }

  &.handshake {
    width: 1.3662109375em;
  }

  &.letter-mail {
    width: 1.06640625em;
  }

  &.catalog {
    width: 1.033203125em;
  }
}

.icon-rs {
  a {
    background-color: $c-greynite;
    border-radius: 50%;
    display: block;
    height: 32px;
    width: 32px;
    line-height: 32px;
    text-align: center;
    transition: background-color 250ms;
    display: flex;
    align-items: center;
    justify-content: center;

    .svg-icon {
      color: $c-white;
    }
  }

  &:last-child a {
    line-height: 31px;
  }
}

.icon-key-point {
  border-radius: 50%;
  height: 62px;
  width: 62px;
  line-height: 48px;
  margin: 0;
  text-align: center;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  &::before,
  &::after {
    content: '';
    border-radius: 50%;
    height: 62px;
    width: 62px;
    position: absolute;
    transform: rotateZ(-45deg);
    left: 0;
    top: 0;
    z-index: 1;
  }

  &::before {
    border: 3px dotted #fff;
    clip-path: polygon(0 0, 50% 0, 50% 100%, 0% 100%);
  }

  &::after {
    border: 3px dotted $c-greynite;
    clip-path: polygon(50% 0, 100% 0, 100% 100%, 50% 100%);
  }

  .svg-icon {
    font-size: em(28);
  }
}

.icon-service {
  display: block;
  margin-bottom: 2.5rem;
  position: relative;
  text-align: center;

  &::before {
    content: '';
    height: 5px;
    width: 24px;
    background-color: $c-green;
    margin-left: -12px;
    position: absolute;
    left: 50%;
    bottom: -1.2rem;
  }

  .svg-icon {
    color: $c-white;
    font-size: em(40);
  }
}

.icon-video {
  display: inline-block;
  position: relative;

  img {
    display: block;
  }

  i {
    background-color: rgba($c-green, .5);
    border-radius: 50%;
    position: absolute;
    line-height: 60px;
    margin-top: -32px;
    margin-left: -32px;
    height: 64px;
    width: 64px;
    left: 50%;
    top: 50%;
    text-align: center;
    transition: background-color 250ms;
    z-index: 2;

    .svg-icon {
      color: $c-greenlite;
      height: 48px;
      width: 48px;
      margin-top: 8px;
      transition: color 250ms;
    }
  }

  &:hover {

    i {
      background-color: rgba($c-greylite, .3);

      .svg-icon {
        color: $c-greynite;
      }
    }
  }
}

.icon-check,
.icon-close {
  border-radius: 50%;
  background-color: $c-white;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 28px;
  line-height: 27px;
  text-align: center;
  width: 28px;
}

.icon-check .check {
  color: $c-green;
}

.icon-close .close {
  font-size: 12px;
}

@media screen and (min-width: $bp-l) {

  .icon-rs {
    a:hover {
      background-color: $c-green;
    }
  }
}
