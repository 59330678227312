/**
 * ==================== @ TABLE LAYOUT
 */

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.table-layout {
  padding: 40px 10px;
  background-color: $c-greylite;
  position: relative;
  overflow-x: auto;
  margin-left: -$gutter;
  margin-right: -$gutter;

  table {
    width: 100%;

    th {
      font-size: em(16);
      font-weight: $fw-medium;
    }

    .body td {
      font-size: em(13);
      text-align: center;

      &:first-child {
        padding-right: 10%;
        text-align: left;
      }
    }

    .bg-cell {
      line-height: 2;
      background-color: $c-whitegrey;
      padding: 1rem;
      margin: 0 6px;

      &.last {
        background-color: $c-white;
      }
    }

    .body:nth-of-type(2) .bg-cell {
      border-top-left-radius: $radius;
      border-top-right-radius: $radius;
    }

    .body:last-child .bg-cell {
      border-bottom-left-radius: $radius;
      border-bottom-right-radius: $radius;
    }
  }

  .table-legend {
    font-size: em(12);
    margin-top: 2rem;
  }


  .m--row,
  .m--row-thin {
    .bg-cell {
      position: relative;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: calc(100% - 24px);
        height: 1px;
        background-color: #b8bfc5;
      }
    }
  }

  .head-row+.m--row,
  .head-row+.m--row-thin,
  .m--row+.m--row-thin {
    .bg-cell::after {
      content: none;
    }
  }

  .icon-close,
  .icon-check {
    height: 25px;
    width: 25px;
  }
}


@media screen and (min-width:$bp-m) {

  .table-layout {
    border-radius: $radius-10;
    padding: 32px 14px 0px 30px;
    margin-left: 0;
    margin-right: 0;

    table {
      width: 100%;

      th {
        font-size: em(20);
        font-weight: $fw-medium;
        padding-bottom: 12px;
      }

      .body td {
        font-size: em(14);
        text-align: center;

        &:first-child {
          padding-right: 10%;
          text-align: left;
        }
      }

      .bg-cell {
        background-color: $c-whitegrey;
        padding: 10px;
        margin: 0 6px;

        &.last {
          background-color: $c-white;
        }
      }

      .body:nth-of-type(2) .bg-cell {
        border-top-left-radius: $radius-10;
        border-top-right-radius: $radius-10;
      }

      .body:last-child .bg-cell {
        border-bottom-left-radius: $radius-10;
        border-bottom-right-radius: $radius-10;
      }
    }
  }
}