.networking {
  border-radius: 0 !important;
  margin-top: 0 !important;
  margin-bottom: 40px !important;
  $el: &;

  &__block {
    position: relative;
    overflow: hidden;
  }

  &__circle {
    pointer-events: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-91%, -50%);
    width: auto;
    height: 728px;
    aspect-ratio: 1;
    fill: $c-green;
  }

  &__content {
    position: relative;
    z-index: 1;
  }

  &__list {
    display: grid;
    gap: 8px;
    grid-template-columns: 50%;
    grid-template-rows: repeat(4, 72px);
    grid-template-areas:
      "a b"
      "a b"
      "c d"
      "c e";
  }

  &__item {
    &1 {
      grid-area: a;
    }

    &2 {
      grid-area: b;
    }

    &3 {
      grid-area: c;
    }

    &4 {
      grid-area: d;
    }

    &5 {
      grid-area: e;
    }
  }

  &__link {
    display: block;
    height: 100%;
    background-color: $c-greynite;
    border-radius: $radius;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: $c-greydark;
      transition: background-color .2s;

      #{$el}__icon,
      #{$el}__text {
        color: $c-white;
      }
    }
  }

  & &__btn {
    height: 100%;
    margin-top: 0;
    margin-bottom: 0;
  }

  &__icon {
    color: $c-green;
    transition: color .2s;
    width: 50px;
    height: 50px;
  }

  &__highlight,
  &__text {
    display: block;
    text-align: center;
  }

  &__highlight {
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    line-height: 1.5;
  }

  &__text {
    font-size: 12px;
    color: #d0d5d9;
    transition: color .2s;
  }

  @media (min-width: $bp-s) {
    &__block {
      padding-top: 12px;
      padding-bottom: 55px;
    }

    &__wrapper {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  @media (min-width: $bp-l) {
    &__icon {
      height: 77px;
      width: 77px;
    }

    &__circle {
      height: 1041px;
      transform: translate(-100%, -50%);
    }
  }

  @media (max-width: 1151px) {
    &.pg__section {
      padding: 0;
    }
  }

  @media (min-width: 1152px) {
    margin-bottom: 120px !important;

    &.pg__section {
      padding-left: 0;
      padding-right: 0;
    }

    &__list {
      gap: 10px;
      grid-template-columns: 240px 240px 240px 310px;
      grid-template-rows: repeat(2, 100px);
      justify-content: center;
      grid-template-areas:
        "a b c d"
        "a b c e";
    }

    &__highlight {
      font-size: 20px;
      margin-top: 14px;
    }

    &__text {
      font-size: 18px;
    }
  }
}