.lp-header {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 2rem;
  padding-top: 1.5rem;

  @media (min-width: $bp-s) {
    flex-direction: row;
    gap: 3rem;
    padding-block: 3rem;
  }

  @media (min-width: $bp-xl) {
    padding-bottom: 4rem;
  }
}

.lp-header__content {
  flex-grow: 999;
  flex-basis: 0;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  min-width: calc(min(20rem, 100%));
}

.lp-header__image-wrapper {
  flex-grow: 1;
  flex-basis: 42.5%;
  min-width: calc(min(14rem, 100%));
  margin-inline: calc(var(--lp-gutter) * -1);

  @media (min-width: $bp-s) {
    align-self: flex-end;
    max-width: 24rem;
    margin-inline: 0;
  }
}

.lp-header__logo-block {
  display: flex;
  gap: 2rem;
}

.lp-header__logo-wrapper {
  align-self: flex-start;
  flex-shrink: 0;
  display: inline-flex;
}

.lp-header__logo {
  width: 95px;
}

.lp-header__tagline {
  align-self: center;
  max-width: 20rem;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.25;
  text-wrap: balance;

  @media (min-width: $bp-s) {
    font-size: 1.25rem;
  }
}
