/**
 * ==================== @ SASS FUNCTIONS
 */

@function path($ressource_path, $base_path: $img-path) {
  @return ($base_path + $ressource_path);
}

@function strip-unit($value) {
  @return $value / ($value * 0 + 1);
}

@function em($pixels, $context: $default-font-size) {
  @return #{strip-unit($pixels)/strip-unit($context)}em;
}

@function rem($pixels, $context: 16px) {
  @return #{strip-unit($pixels)/strip-unit($context)}rem;
}

@function lh($pixels, $context: $default-font-size) {
  @return strip-unit($pixels / $context);
}

@function svg-url($svg, $fill: null){
  //  Add missing namespace
  @if not str-index($svg,xmlns) {
    $svg: str-replace($svg, '<svg', '<svg xmlns="http://www.w3.org/2000/svg"');
  }
  // Add fill to paths
  @if $fill {
    $svg: str-replace($svg, '<path', '<path fill="'+$fill+'"');
  }
  //  Chunk up string in order to avoid
  //  "stack level too deep" error
  $encoded:'';
  $slice: 2000;
  $index: 0;
  $loops: ceil(str-length($svg)/$slice);
  @for $i from 1 through $loops {
    $chunk: str-slice($svg, $index, $index + $slice - 1);
    //   Encode
    $chunk: str-replace($chunk,'"', '\'');
    $chunk: str-replace($chunk,'%', '%25');
    $chunk: str-replace($chunk,'&', '%26');
    $chunk: str-replace($chunk,'#', '%23');
    $chunk: str-replace($chunk,'{', '%7B');
    $chunk: str-replace($chunk,'}', '%7D');
    $chunk: str-replace($chunk,'<', '%3C');
    $chunk: str-replace($chunk,'>', '%3E');

    $encoded: #{$encoded}#{$chunk};
    $index: $index + $slice;
  }

  @return url("data:image/svg+xml,#{$encoded}");
}

@function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);
  @return if($index,
    str-slice($string, 1, $index - 1) + $replace +
    str-replace(str-slice($string, $index +
    str-length($search)), $search, $replace),
    $string);
}

