/**
 * ==================== @ AGENCY - List & page
*/

.agencies {
  .page-header {
    span {
      font-size: em(14);
      font-weight: $fw-semibold;
      text-transform: uppercase;
    }
  }

  &-grid {
    display: flex;
    flex-direction: column-reverse;
    flex-wrap: wrap;
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }

  &-map,
  &-locality {
    flex: 0 0 auto;
    width: 100%;
    max-width: 100%;
    background-color: #fff;
  }

  &-map {
    border-radius: $radius;
    overflow: hidden;
  }

  &-locality {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
  }

  &-list {
    width: 100%;
  }

  &__content {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-top: 45px;
  }

  &__wrapper {
    position: relative;
    flex: 1 0;
    overflow: hidden;
  }

  &__list {
    margin-top: 1rem;
    margin-bottom: 1rem;
    position: absolute;
    inset: 0 0 auto 0;
    display: flex;
    flex-direction: column;
    gap: 11px;
    padding-inline-start: 0 !important;
  }

  &__item {
    border-bottom: 1px solid #b8bfc5;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 3px;
    flex-direction: column;

    &:not(:first-child) {
      padding-top: 10px;
    }
  }

  &__town {
    font-weight: 600;
    font-size: 14px;
    color: $c-greynite;
    text-transform: uppercase;
  }

  &__right {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__number {
    min-width: 110px;
  }

  &__tel {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  &__link {
    i {
      border-radius: 50%;
      flex-shrink: 0;
      height: 33px;
      width: 33px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      background-color: $c-greynite;
      border: 4px solid #929da6;
      transition: background-color 250ms, border-color 250ms;

      &::after {
        background-color: $c-greynite;
      }
    }

    .svg-icon {
      color: #fff;
      transition: color 250ms;
      width: 10px;
      height: 10px;
    }

    &:hover,
    &.active {
      i {
        background-color: $c-greenlite;
        border-color: rgba($c-green, 0.5) !important;
      }

      .svg-icon {
        color: $c-greynite;
      }
    }
  }

  &__btnContainer {
    margin-top: 32px;
    display: flex;
    justify-content: center;
  }

  &__btn {
    padding-top: 13px;
    padding-bottom: 13px;
  }

  @media (min-width: 630px) {
    &__item {
      flex-direction: row;

      &:not(:first-child) {
        padding-top: 0;
      }
    }

    &__right {
      gap: 23px;
      flex-shrink: 0;
    }
  }
}

.agencies-list {
  /* Specific button in list
	------------------------*/

  .btn-agency {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.3rem;
    padding-bottom: 0.3rem;
    width: 100%;
    gap: 15px;
    white-space: nowrap;

    .btn {
      padding: 0;
    }

    div {
      font-size: em(14);
      transition: color 200ms;

      &:first-child {
        font-weight: $fw-semibold;
        text-transform: uppercase;
      }
    }

    &:hover {
      div,
      span {
        color: $c-green;
      }

      span {
        i {
          background-color: $c-greenlite;
          border-color: rgba($c-green, 0.5) !important;
        }
      }
    }
  }

  li:not(:last-child) .btn-agency {
    border-bottom: 1px solid $c-greylite;
  }
}

.agency__infos,
.agencies-locality {
  display: flex;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.agencies-list {
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;
}

.agency__infos > div {
  width: 100%;
}

.agency {
  &__section {
    margin: 30px auto;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    border-radius: 10px;
  }

  &__title {
    color: $c-greynite; 
  }

  &__coord,
  &__map {
    flex: 0 0 auto;
    width: 100%;
    max-width: 100%;
  }

  &__map {
    border-radius: $radius-10;
    overflow: hidden;
  }
}

.agency__infos {
  background-color: $c-white;
  height: 100%;
  padding-bottom: 40px;

  p {
    color: $c-greynite;
    font-size: em(14);
  }

  .agency__name {
    font-size: em(20);
    font-weight: $fw-bold;
    margin-bottom: 0;
  }

  .agency__adress,
  .agency__phone,
  .agency__mail {
    padding-bottom: 0.5rem;
    display: block;

    .btn {
      display: flex;
      justify-content: flex-start;
    }

    .svg-icon {
      font-size: 15px;
    }
  }

  .agency__adress {
    .btn {
      font-weight: 400;
    }
  }

  .agency__phone,
  .agency__mail {
    font-weight: 700;
  }

  .agency__phone,
  .agency__mail {
    word-break: break-all;
  }

  .agency__phone {
    font-weight: $fw-semibold;
    margin-bottom: 0;
  }

  .agency__mail {
    text-decoration: underline;
  }

  .agency__hours {
    width: 100%;

    .day {
      // color: $c-greydark;
      font-size: em(14);
      padding: 12px 0.5rem;
      margin: 0;
      border-bottom: 1px solid #b8bfc5;
      text-transform: uppercase;
      display: flex;
      justify-content: space-between;

      span {
        font-weight: 700;
      }

      &.active {
        background-color: $c-greylite;
      }
    }

    span {
      display: inline-block;
      width: 110px;
    }

    strong {
      letter-spacing: 1px;
    }
  }

  @media (min-width: $bp-l) {
    &__infos {
      padding-top: 40px;
    }
  }
}

.agency-body {
  background-color: $c-whitegrey;
}

.agency-header {
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 52px;
  padding-bottom: 64px;
  margin-bottom: -0.5rem;
}

.agency-slides {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 38px;

  figure {
    margin: 1rem 0.5rem;
    overflow: hidden;

    picture {
      display: block;
    }
  }

  img {
    border-radius: $radius-10;
  }
}

.agency-main {
  max-width: 690px;
}

.agency-caption {
  margin-bottom: 24px;
}

.certifications-agency {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: -7px;
}

.certifications-item {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 7px;
  width: 50%;
}

@media screen and (min-width: $bp-s) {
  .certifications-item {
    width: calc(100% / 3);
  }
}

@media screen and (min-width: $bp-m) {
  .agency-item-notAlone {
    width: calc(100% / 3);
  }
}

@media screen and (min-width: $bp-l) {
  .agencies {
    &-grid {
      flex-direction: row;
    }

    &-map {
      flex: 1 0 0%;
    }

    &-locality {
      width: 50%;
      padding-top: 30px;
      padding-bottom: 30px;
      margin-bottom: 0;
    }

    &-list {
      width: 77%;
      margin-left: 15px;
    }
  }

  .agency {
    &__section {
      border-radius: 0;
      gap: 66px;
    }

    &__adress,
    &__phone,
    &__mail {
      .btn {
        display: inline-flex;
      }
    }

    &__coord {
      width: 40%;
    }

    &__map {
      flex: 1 0 0%;
    }

    &__hours {
      width: max-content;

      .day {
        justify-content: flex-start;
      }
    }

    &-header {
      &-horizontal {
        flex-direction: row;
        justify-content: center;

        .agency-slides {
          margin-right: 30px;
        }
      }
    }
  }

  .certifications-item {
    width: auto;
  }
}

@media screen and (max-width: 767px) {
  .agencies {
    .l-wrapper-frame {
      padding: 4px 8px;
    }

    &-locality {
      // padding: 40px 35px;
    }
  }
}
