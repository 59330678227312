.video-banner {
  padding-top: 3rem;

  @media (min-width: $bp-m) {
    padding-bottom: 3rem;
  }
}

.video-banner-inner {
  display: block;
  background-color: $c-green;
  transition-property: filter;
  transition-duration: 150ms;

  &:hover,
  &:focus-visible {
    filter: drop-shadow(0 0 0.625rem rgba($c-greynite, 0.25));

    .video-banner__image-wrapper {
      &::after {
        opacity: 1;
      }
    }

    .video-banner__icon {
      background-color: $c-greydark;
    }
  }
}

.video-banner-layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: $layout-width;
  margin-inline: auto;
  padding-inline: $gutter;

  @media (min-width: $bp-m) {
    flex-direction: row;
    justify-content: center;
    gap: 4rem;
  }
}

.video-banner__image-wrapper {
  position: relative;
  max-width: 28rem;
  aspect-ratio: 1.75;
  margin-top: -3rem;
  border-radius: 0.5rem;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    inset: 0;
    background-color: rgba($c-greynite, 0.25);
    opacity: 0;
    transition-property: opacity;
    transition-duration: 150ms;
  }

  @media (min-width: $bp-m) {
    margin-bottom: -3rem;
  }
}

.video-banner__image {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.video-banner__title-wrapper {
  flex-shrink: 0;
  display: inline-flex;
  align-items: center;
  gap: 1rem;
  padding-block: 1.25rem;

  @media (min-width: $bp-m) {
    padding-block: 2rem;
  }
}

.video-banner__title {
  margin-block: 0;
  text-align: right;

  &::before {
    content: none;
  }

  small {
    display: block;
    font-size: 1.25rem;

    @media (min-width: $bp-m) {
      font-size: 1.625rem;
    }
  }
}

.video-banner__icon {
  flex-shrink: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 2.875rem;
  height: 2.875rem;
  border: 0.3125rem solid $c-white;
  border-radius: 50%;
  background-color: $c-greynite;
  font-size: 0.875rem;
  color: $c-white;
  transition-property: background-color;
  transition-duration: 150ms;

  .svg-icon {
    margin-left: 0.125rem;
    color: inherit;
  }
}
