/**
 * ==================== @ BUTTONS
 */

@mixin circle($bgc, $fgc, $size: 32px, $color: null) {
  i {
    position: relative;
    flex-shrink: 0;
    background-color: $bgc;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 20px;
    height: $size;
    width: $size;
    text-align: center;
    transition: background-color 250ms, border-color 250ms;

    &::after {
      content: '';
      position: absolute;
      width: calc(100% - 8px);
      height: calc(100% - 8px);
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: $fgc;
      border-radius: 50%;
    }
  }

  .svg-icon {
    @if $color {
      color: $color;
    }

    position: relative;
    z-index: 1;
  }
}

.btn-wrapper {
  padding: 1rem 0;
  padding-right: 20px;

  &:not(.m--center) {
    display: inline-block;
  }

  &.m--center {
    text-align: center;
  }

  &.m--right {
    text-align: right;
  }

  &+& {
    padding-top: 0;
  }
}

.btn {
  background-color: $c-green;
  border-radius: $radius;
  color: $c-greynite;
  display: inline-flex;
  font-weight: 600;
  align-items: center;
  justify-content: space-between;
  font-size: em(14);
  padding: .5rem 1.2rem;
  position: relative;
  transition: background-color 250ms;
  text-align: left;
  gap: 8px;
  border: 2px solid transparent;

  .download {
    transform: rotateZ(90deg);
  }

  &.m--center {
    text-align: center;
    width: 100%;
  }

  &.m--btnstyle2 {
    background-color: transparent;
    border-color: $c-green;

    i {
      background-color: rgba($c-green, .6);

      &::after {
        background-color: $c-green;
      }
    }
  }

  @include circle($bgc: rgba($c-whitegrey, .4), $fgc: rgba($c-whitegrey, .6));

  strong {
    margin-left: 0;
  }

  .svg-icon {
    position: relative;
    z-index: 1;
    color: $c-greynite;
    font-size: 10px;
    transition: color 250ms;
  }

  &.m--back {
    padding: .75rem 1rem;
    white-space: nowrap;

    i {
      margin-left: 0;
      margin-right: 15px;
    }
  }

  &.m--linkback {
    font-size: em(12);
    font-weight: $fw-semibold;
    padding: .75rem 1rem;
    background-color: transparent;
    color: #596B79;

    i {
      background-color: #E9EBED;
      border: 4px solid #F4F5F6;
      line-height: 18px;
      height: 28px;
      width: 28px;
      margin-left: 0;
      margin-right: 15px;

      .svg-icon {
        color: #3B4F60;
      }
    }

    &:hover .svg-icon {
      color: $c-white;
    }
  }

  &.m--cta {
    padding: .75rem 1rem .75rem 4rem;

    i {
      left: .75rem;
      right: auto;
    }
  }

  &.m--big {
    display: flex;
    align-items: center;
    background-color: $c-whitegrey;
    color: $c-grey;
    margin: .2rem 0;
    width: 100%;

    strong {
      display: block;
      color: $c-greynite;
    }

    .svg-icon {
      color: $c-white;
    }

    i {
      background-color: $c-greynite;
      border: 4px solid #929DA6;

      &::after {
        background-color: $c-greynite;
      }
    }
  }

  &:not(span):hover,
  &:not(span).active {
    // background-color: $c-green;
    // color: $c-white;

    i {
      background-color: rgba($c-greenlite, .4);
      border-color: rgba($c-green, .5) !important;
    }

    .svg-icon {
      color: $c-white;
    }
  }

  &.m--dark {
    background-color: $c-greynite;
    color: $c-white;

    &:not(span):hover {
      i {
        background-color: $c-greenlite;
      }
    }

    i {
      background-color: rgba(#fff, .4);
      border-color: rgba($c-green, .5) !important;

      &::after {
        background-color: $c-green;
      }
    }

    .svg-icon {
      color: $c-greynite;
    }
  }

  &.m--white {
    background-color: $c-white;
    color: $c-greynite;

    &:not(span):hover {
      background-color: $c-green;

      i::after {
        background-color: $c-white;
      }

      .svg-icon {
        color: inherit;
      }
    }

    i {
      background-color: $c-greenlite;
      border-color: rgba($c-green, .5) !important;

      &::after {
        background-color: $c-green;
      }
    }
  }
}

.btn-link {
  color: $c-grey;
  font-size: em(13);
  font-weight: $fw-semibold;
  padding: 0 1.2rem;
  text-transform: uppercase;
  display: block;

  &:not(span).active,
  &:not(span):hover {
    color: $c-green;
  }
}

.btn-cta-call {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-weight: $fw-semibold;
  padding: .5rem 1rem;
  transition: background-color 250ms, color 250ms;

  i {
    background-color: $c-green;
    border: 4px solid $c-greenlite;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    line-height: 1;
    height: 34px;
    width: 34px;
    text-align: center;
    transition: background-color 250ms;
  }

  span {
    margin-left: .5rem;
  }

  .svg-icon {
    height: 55%;
    width: 55%;
    transition: color 250ms;
  }

  &:not(span):hover {
    background-color: $c-green;
    color: $c-white;

    i {
      background-color: $c-greenlite;
      border-color: rgba($c-green, .5);
    }

    .svg-icon {
      color: $c-white;
    }
  }
}

.btn-simple {
  @include circle($bgc: rgba($c-whitegrey, .4), $fgc: rgba($c-whitegrey, .6));
}


@media all and (min-width : $bp-l) {

  .btn {

    &.m--big {
      padding: 1.1rem 1.2rem;
      font-size: em(18);

      i {
        border: 5px solid #929DA6;
        line-height: 30px;
        height: 46px;
        width: 46px;
      }

      strong {
        font-size: em(20, 18);
      }
    }
  }
}

