.img-grid {
  background-color: $c-greylite;

  .pg__section {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 64px;
    padding-bottom: 48px;
    background-color: transparent;

    @at-root .l-wrapper-frame & {
      border-radius: 0;
    }
  }

  .grid-layout .grid-col-content {
    padding: 0;
  }

  &__wrapper {
    display: grid;
    gap: 20px;
  }

  @media (min-width: 576px) {
    &__wrapper {
      grid-template-columns: 1fr 1fr;
    }
  }
  .figure__picto {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
}