.text-legend {

  &__wrapper {
    padding-top: 48px;
    padding-bottom: 48px;
    position: relative;
    overflow: hidden;
  }

  &__circle {
    pointer-events: none;
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(91%, -50%);
    width: auto;
    height: 728px;
    aspect-ratio: 1;
    fill: $c-green;
    z-index: -1;
  }

  h2 {
    all: unset;
    font-weight: 600;
    font-size: rem(20);

    &::before {
      content: none;
    }
  }

  .grid-layout .grid-col-content {
    background-color: $c-greynite;
    border-radius: $radius;
    padding: 28px;

    &,
    h2,
    p {
      color: #fff;
    }
  }

  .entry {
    flex-direction: column;
    align-items: flex-start;
  }

  li {
    padding-bottom: 12px;
  }

  &__note {
    display: block;
    text-align: right;
    font-style: italic;
    font-size: 14px;
    opacity: 0.6;
    margin-top: 12px;
    margin-bottom: 48px;
  }

  @media (min-width: $bp-l) {
    &__circle {
      height: 1041px;
      transform: translate(101%, -50%);
    }

    ul {
      columns: 2;
      column-gap: 72px;
    }
  }

  @media (min-width: $bp-lx) {
    .section-seo .grid-layout .grid-col-content {
      padding: 28px;
    }
  }
}