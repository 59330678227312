.reinsurance {
  position: relative;
  max-width: $layout-width;
  margin-top: 1.5rem;
  margin-inline: auto;
  padding-inline: $gutter;

  @media (min-width: $bp-s) {
    margin-top: 2rem;
  }

  @media (min-width: $bp-l) {
    margin-top: -84px;
  }
}

.reinsurance__items {
  --inline-gap: 1rem;

  display: flex;
  flex-wrap: wrap;
  gap: 2rem var(--inline-gap);

  @media (min-width: $bp-l) {
    justify-content: space-evenly;
    padding-block: 1.5rem;
    padding-inline: 1rem;
    border-radius: 0.5rem 0.5rem 0 0;
    background-color: $c-white;
  }
}

.reinsurance__item-wrapper {
  flex-grow: 1;
  flex-basis: calc(1 / 2 * 100% - var(--inline-gap) * 1 / 2);
  display: inline-flex;
  min-width: 164px;

  @media (min-width: $bp-s) {
    flex-basis: calc(1 / 3 * 100% - var(--inline-gap) * 2 / 3);
  }

  @media (min-width: $bp-l) {
    flex-grow: 0;
    flex-basis: auto;
    min-width: auto;
  }
}

.reinsurance__item {
  display: inline-flex;
  align-items: center;
  gap: 0.75rem;
  color: $c-greynite;
  transition-property: color;
  transition-duration: 150ms;

  &:hover,
  &:focus-visible {
    color: $c-green;
  }
}

.reinsurance__item__icon {
  display: inline-flex;
  font-size: 2.25rem;

  .svg-icon {
    color: inherit;
  }
}

.reinsurance__item__label {
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1rem;

  @media (min-width: $bp-s) {
    font-size: 1rem;
    line-height: 1.125rem;
  }

  @media (min-width: $bp-1280) {
    font-size: 1.125rem;
    line-height: 1.25rem;
  }
}
