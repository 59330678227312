/**
 * ==================== @ BLOCS
*/

.block__rs {
  max-width: 200px;
  margin: 0 0 1rem auto;
  text-align: center;

  h3 {
    font-size: em(18);
    font-weight: normal;
  }

  li {
    display: inline-block;
    margin: .4rem;
  }
}

/**
 * === Service catalogue
*/

.block-product {
  background-color: $c-white;
  border-radius: $radius;
  display: flex;
  margin: .5rem;
  overflow: hidden;

  figure.product__img {
    max-width: 40%;
    padding: 0 !important;
    overflow: hidden;

    picture {
      display: block;
      height: 100%;
    }

    img {
      transition: transform 350ms;
      height: 100%;
      object-fit: cover;
    }
  }

  .product__metas {
    padding-left: 1rem;
    padding-top: 0;
  }

  .product__title {
    color: $c-greynite;
    font-size: em(20);
    font-weight: $fw-bold;
    margin-bottom: 0;
  }

  .product__ref {
    color: $c-greynite;
    font-size: em(13);
    text-transform: uppercase;
    margin-top: 0;
  }

  .product__perf {
    color: $c-greynite;
    margin: .5rem 0;
    padding-left: 1rem;

    li {
      font-size: em(14);
    }

    li:first-child {
      list-style: none;
      margin-left: -1rem;
      font-weight: $fw-semibold;
      text-transform: uppercase;
    }

    span {
      display: inline-block;
      // width: 100px;
    }
  }

  .product__price {
    color: $c-greynite;
    font-size: em(20);
    font-weight: $fw-medium;

    small {
      display: block;
      font-size: em(11, 20);
    }
  }
}

@media all and (min-width : $bp-m) {
  .block-product {
    border-radius: $radius-10;

    &:hover {
      .product__img img {
        transform: scale(1.03, 1.03);
      }
    }

    .product__metas {
      padding-left: 2rem;
      padding-top: 3rem;
    }

    .product__perf {
      margin: 3rem 0;
    }
  }
}