/**
 * ==================== @ MENU NAV
 */
.menu {
  border-top: 1px solid $c-greylite;
  border-bottom: 1px solid $c-greylite;
  padding: 10px 0;
  position: relative;
  overflow: hidden;

  a,
  .submenu-btn {
    display: block;
    font-size: rem(15);
    font-weight: $fw-semibold;
    padding: 8px 18px;
  }

  .menu-item,
  .submenu-item {
    margin: 22px 0;

    &.m--has-children {
      >a {
        padding-right: 56px;
        position: relative;

        i {
          display: block;
          height: 40px;
          width: 40px;
          position: absolute;
          top: calc(50% - 20px);
          right: 0;
          text-align: center;
          transition: background-color 250ms, border-color 250ms;
        }

        .svg-icon {
          color: inherit;
          left: 50%;
          position: absolute;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }

  .submenu-item {
    >a {
      font-weight: $fw-regular;

      span {
        font-weight: $fw-semibold;
      }
    }
  }

  .submenu-btn {
    border-bottom: 1px solid $c-greylite;
    color: $c-greynite;
    line-height: inherit;
    // margin-bottom: 20px;
    position: relative;
    text-align: left;
    width: 100%;

    &::before {
      @include background-svg($svg-arrow-left, $c-greynite);
      content: '';
      height: 12px;
      left: 0;
      position: absolute;
      top: calc(50% - 6px);
      width: 8px;
    }
  }

  .submenu-container {
    background-color: #fff;
    left: 0;
    position: absolute;
    top: 0;
    transition: transform .25s;
    transform: translateX(100%);
    width: 100%;
    z-index: 1;

    &.is-opened {
      transform: translateX(0);
    }

    &.m--level2 {
      background-size: 0;
    }
  }

  .submenu {
    padding: 10px 0;
  }

}

// @media all and (min-width : $bp-m) {
// 	.menu {
// 		a,
// 		.submenu-btn {
// 			padding: 8px 16px;
// 		}

// 		.menu-item,
// 		.submenu-item {
// 			margin: 10px 0;
// 		}
// 	}
// }


@media all and (min-width : $bp-l) {

  .menu {
    border: 0;
    height: auto !important;
    overflow: visible;
    padding: 0;
    display: flex;
    gap: 24px;
    border-radius: $radius;
    background-color: $c-whitegrey;
    position: relative;

    .menu-item {
      display: inline-block;
      font-size: em(15);
      font-weight: $fw-semibold;
      margin: 0;

      >a {
        padding: 16px 20px;
        transition: background-color .25s, color .25s;

        &:hover {
          background-color: $c-greynite;
          color: #fff;
        }
      }

      &:not(:first-child) {
        // position: relative;

        &::before {
          background-color: $c-whitegrey;
          content: '';
          height: 24px;
          left: 0;
          position: absolute;
          top: calc(50% - 12px);
          transition: opacity .35s;
          width: 2px;
        }

        &:hover {
          &::before {
            opacity: 0;
            transition: opacity .12s;
          }
        }
      }

      &.m--has-children {
        >a {
          background-color: transparent;
          border-radius: 0;
          color: $c-greynite;

          i {
            background-color: transparent;
            border: none;
            height: 20px;
            right: 20px;
            top: calc(50% - 10px);
            transform: rotate(90deg);
            width: 20px;
          }
        }

        &:hover {
          >a {
            background-color: $c-greynite;
            color: #fff;
          }

          >.submenu-container {
            opacity: 1;
            pointer-events: auto;
          }
        }
      }
    }

    .submenu-btn {
      display: none;
    }

    .submenu-container {
      min-height: 250px;
      opacity: 0;
      padding: 10px 0;
      pointer-events: none;
      transform: none;
      transition: opacity .25s;

      a {
        color: inherit;
        transition: padding-left .25s;

        &:hover {
          padding-left: 32px;
        }
      }

      &.m--level1 {
        background-color: $c-greynite;
        border-radius: 0 0 $radius-10 $radius-10;
        color: #fff;
        padding-right: calc(60% + 60px);
        top: 100%;
      }

      &.m--level2 {
        background-color: $c-whitegrey;
        border-radius: $radius-10 0 0 $radius-10;
        color: $c-greynite;
        height: 100%;
        left: 40%;
        opacity: 0;
        overflow: hidden;
        padding-right: 250px;
        pointer-events: none;
        transition: opacity .25s;
        width: 60%;

        &::after {
          background-image: inherit;
          background-size: 100%;
          background-repeat: no-repeat;
          content: '';
          height: 460px;
          position: absolute;
          right: -230px;
          top: calc(50% - 230px);
          width: 460px;
        }
      }
    }

    .submenu-item {

      &.m--has-children {
        >a {
          background-color: transparent;
          transition: background-color .25s, padding-left .25s;

          i {
            opacity: 0;
            transition: opacity .25s;
          }

          &::after {
            content: '';
            height: 100%;
            left: 100%;
            position: absolute;
            top: 0;
            width: 60px;
          }
        }

        &:hover {
          >a {
            background-color: $c-green;

            i {
              opacity: 1;
            }
          }

          >.submenu-container {
            pointer-events: auto;
            opacity: 1;
            transition: opacity .25s;
          }
        }
      }
    }

  }

}