.text-img {
  &_wide {
    position: relative;
    margin-bottom: 10px;
    overflow: hidden;

    .pg__section.default-section {
      border-radius: 0;
    }

    h2 {
      color: $c-green;
      font-weight: 500;
      color: $c-greynite;
    }

    .grid-layout {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      align-content: flex-start;
    }

    .grid-col-txt,
    .grid-col-img {
      width: 100%;
    }

    @at-root body.page .l-wrapper-frame & {
      .grid-col-img {
        figure.img-radius img {
          width: auto;
        }
      }
    }

    @media (min-width: $bp-m) {
      padding-bottom: 43px;

      @at-root body.page .l-wrapper-frame & {
        &_rounded {
          margin-top: 30px;
          margin-bottom: 30px;
          padding-top: 43px;
        }
      }

      .grid-layout {
        flex-direction: row;

        .grid-col-content {
          padding: 0;
        }
      }

      .grid-col-txt {
        width: 50%;

        &_left {
          padding-right: 30px;
        }

        &_right {
          padding-left: 30px;
        }

        &_left {
          padding-left: calc(((100% - #{$layout-width}) / 2) + 8.3333%);

          .grid-col-content {
            padding-left: $layout-padding !important;
            padding-right: 15px !important;
          }
        }

        &_right {
          padding-right: calc(((100% - #{$layout-width}) / 2) + 8.3333%);
          margin-left: auto;

          .grid-col-content {
            padding-right: $layout-padding !important;
            padding-left: 15px !important;
          }
        }

        @at-root body.page .l-wrapper-frame & {

          // &_left,
          &_right {
            width: calc(100% * 2/3);
          }
        }
      }

      .grid-col-img {
        width: auto;
        max-width: 50%;

        &_left,
        &_right {
          picture img {
            aspect-ratio: 1.12;
            object-fit: cover;
          }
        }

        @at-root body.page .l-wrapper-frame & {

          &_right,
          &_left {
            // width: calc(((100% - #{$layout-width}) / 2) + (#{$layout-width} * .32));

            figure {
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
            }
          }

          // &_left,
          &_left {
            width: 38%;
          }

          &_right {
            width: 48%;
          }
        }


        &_left {
          position: absolute;
          inset: 50% auto auto 0;
          transform: translateY(-50%);

          figure.img-rounded::after {
            transform: scaleX(-1);
          }

          figure.img-rounded::after {
            left: auto;
          }

          .mask-desk {
            inset: 50% -1px 0 auto;
            transform: translateY(-50%) scaleX(-1);
          }
        }

        &_right {
          position: absolute;
          inset: 50% 0 auto auto;
          transform: translateY(-50%);

          .mask-desk {
            inset: 50% auto 0 0;
            transform: translateY(-50%);
          }
        }
      }

      h2 {
        font-size: rem(32);
      }

      h3 {
        margin-top: rem(55);
        margin-bottom: rem(30);
      }
    }

    // @media (min-width: $bp-m) and (max-width: calc(#{$layout-width} - 1px)) {
    //   .grid-col-img {
    //     @at-root body.page .l-wrapper-frame & {
    //       width: 50vw;
    //     }
    //   }
    // }

    @media (max-width: calc(#{$bp-m} - 1px)) {
      .grid-col-img {
        order: 0;
        width: 100vw;
        margin-left: 50%;
        transform: translateX(-50%);
      }

      .grid-col-txt {
        order: 1;
      }
    }

    @media (min-width: #{$layout-width}) {
      .grid-col-txt {
        @at-root body.page .l-wrapper-frame & {

          &_left,
          &_right {
            width: calc(5/12 * #{$layout-width});
          }
        }

        @at-root body.elementOnRightNotPresent .l-wrapper-frame & {

          &_right {
            width: 84%;
          }
        }
      }

      .grid-col-img {
        @at-root body.page .l-wrapper-frame & {

          // &_right,
          &_left {
            width: calc(((100% - #{$layout-width}) / 2) + (3/12 * #{$layout-width}));
          }
        }
      }
    }
  }
}