.lp-text-image {
  padding-block: 3rem;

  @media (min-width: $bp-xl) {
    padding-block: 4rem;
  }
}

.lp-text-image__layout {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 2rem;

  @media (min-width: $bp-s) {
    gap: 3rem;
  }
}

.lp-text-image__content {
  flex-grow: 999;
  flex-basis: 0;
  min-width: calc(min(20rem, 100%));
}

.lp-text-image__image {
  flex-grow: 1;
  flex-basis: 42.5%;
  min-width: calc(min(12rem, 100%));
  max-width: 24rem;
}
