/*
 * ==================== @ SLICK THEME
 */

.slider {
  display: flex;
  transition: opacity 400ms ease-out;

  .slide-news {
    width: 33%;
  }

  .slide-news:nth-child(4),
  .slide-news:nth-child(5),
  .slide-news:nth-child(6) {
    display: none;
  }

  &.slick-initialized {
    display: inherit;

    .slide-news:nth-child(4),
    .slide-news:nth-child(5),
    .slide-news:nth-child(6) {
      display: block;
    }
  }

  .slider__slide {
    position: relative;
  }
}

.slide-news-block,
.slide-news-container {
  display: flex;
}

.slick-track {
  display: flex;

  &::before,
  &::after {
    content: none;
  }
}

.slick-slide {
  height: auto;
}

/* Slider news home
----------------------------------*/

// .slider-news-home__container {
//   height: 100%;
// }

.slide-news {
  background-color: $c-whitegrey;
  border-radius: $radius;
  overflow: hidden;
  margin: 7px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .slide-image {
    display: block;
    border-bottom: 5px solid $c-greylite;
    overflow: hidden;
    padding: 0 !important;

    picture {
      display: block;
    }

    img {
      transition: transform 250ms;
      width: 100%;
    }
  }

  .slide-publish {
    background-color: $c-white;
    border-radius: $radius;
    color: $c-greynite;
    display: inline-block;
    font-size: em(13);
    font-style: italic;
    left: 1rem;
    top: -1.2rem;
    position: relative;
    padding: .3rem .6rem;
  }

  .slide-tag {
    background-color: $c-white;
    border-radius: $radius;
    color: $c-green;
    display: block;
    font-size: em(12);
    font-weight: $fw-semibold;
    margin-left: 1rem;
    padding: .2rem .7rem;
    width: max-content;
    min-height: rem(24);

    &_inactive {
      background-color: transparent;
    }
  }

  .slide-caption {
    font-size: em(18);
    font-weight: $fw-semibold;
    padding: .5rem 1rem 30px;
  }

  &:hover img {
    transform: scale(1.05, 1.05);
  }
}

.slider-related-news {

  .slide-news {
    background-color: $c-white;
  }

  .slide-caption {
    margin-top: -1rem;
  }
}

.slider-related-news,
.slider-news {
  .slick-arrow {
    background-color: #596B79;
    border: 4px solid #3B4F60;
    border-radius: 50%;
    font-size: 0;
    height: 32px;
    width: 32px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    transition: background-color 250ms, border-color 250ms;

    &::before {
      content: '';
      height: 9px !important;
      width: 9px !important;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;
    }
  }

  .slick-prev {
    left: 10px;

    &::before {
      @include background-svg($svg-arrow-left, #fff);
    }
  }

  .slick-next {
    right: 10px;

    &::before {
      @include background-svg($svg-arrow, #fff);
    }
  }
}

.slider-news {
  .slick-prev {
    left: 0;
  }

  .slick-next {
    right: 0;
  }
}

@media only screen and (max-width: 639px) {

  .slide-news {

    .slide-caption {
      font-size: em(15);
    }
  }
}

@media only screen and (min-width : $bp-m) {
  .slider-news-home__container {
    padding: 10px;
  }

  .slide-news {
    margin: .5rem;
  }
}

@media only screen and (min-width: $bp-l) {

  .slider-related-news {
    .slick-arrow:hover {
      background-color: #DBE79D;
      border-color: $c-green;
    }
  }
}