.certifications {
  background-color: $c-greylite;
  text-align: center;

  .pg__section {
    margin-top: 0;
    margin-bottom: 0;
    background-color: transparent;
  }

  .l-wrapper {
    padding-top: 63px;
    padding-bottom: 63px;
  }

  h4 {
    font-size: em(20);
    font-weight: 600;
  }

  ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 480px;
    margin: 0 auto;
  }

  li {
    padding: .5rem 1rem;

    img {
      max-height: 56px;
      max-width: 180px;
      width: 100%;
    }
  }

  @media (min-width: 768px) {
    .l-wrapper {
      margin-left: 8.3333%;
      margin-right: 8.3333%;
      padding-top: 28px;
      padding-bottom: 28px;
    }

    .sv-u-1 {

      &:first-child {
        padding-top: 1rem;
        text-align: left;
      }
    }

    ul {
      justify-content: flex-end;
      max-width: none;

      li:nth-of-type(2) {
        margin-top: -6px;
      }
    }
  }
}