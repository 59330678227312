/**
 * ==================== @ GENERIC FLOW TOOLS
 */
body.page {
  @media (min-width: $bp-lx) {
    main {
      position: static;
    }
  }

  .l-wrapper-frame {
    @media (min-width: 768px) {
      .pg__section {
        padding-left: $gutterLeft;
      }

      .page-header {
        padding-left: $gutterLeft;
      }
    }

    @media (min-width: $layout-width) {
      .pg__section {
        &:not(.exempted) {
          padding-right: $gutterRight;
        }
      }

      .page-header {
        padding-right: $gutterRight;
      }
    }
  }

  .page-header > *:not(.page-header__right, .page-header__picture) {
    position: relative;
    z-index: 2;
  }

  .page-header__right {
    float: right;
    right: -$gutter;
    top: -32px;
    overflow: hidden;

    @media (min-width: $layout-width) {
      float: none;
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  .page-header__picture {
    width: 36vw;
    height: 36vw;
    display: block;
    position: relative;
    max-height: 300px;
    max-width: 300px;

    @media (min-width: $layout-width) {
      width: $sizeCircle;
      height: $sizeCircle;
      max-height: none;
      max-width: none;
    }

    &::after {
      content: "";
      background-image: url(path("layout/circle.svg"));
      background-repeat: no-repeat;
      background-position: center;
      background-size: 80%;
      position: absolute;
      height: 100%;
      width: 100%;
      top: var(--topAside);
      right: var(--rightAside);
      z-index: 2;

      @media (min-width: $bp-lx) {
        background-size: 85%;
      }
    }
  }

  .page-header__img {
    object-fit: cover;
    position: absolute;
    top: var(--topAside);
    right: var(--rightAside);
    height: 100%;
    width: 100%;
    border-radius: 100%;
  }
}

body {
  &.agencies {
    .l-wrapper-frame {
      @media (min-width: $bp-lx) {
        .pg__section {
          padding-left: $gutterLeft;
        }
      }
    }
  }
}

.l-clearfix {
  @extend %clearfix;
}

.l-wrapper {
  letter-spacing: 0.1px;
  margin: 0 auto;
  padding: 1rem $gutter;
  position: relative;
  max-width: $layout-width;
  z-index: 1;

  &.m--news-inner {
    max-width: 60em;
  }
}

.l-unlist {
  list-style: none;
  margin: 0;
  padding: 0;
}

.l-untitle {
  font-size: inherit;
  font-weight: inherit;
  margin: 0;
}

.l-center {
  text-align: center;
}

.l-right {
  text-align: right;
}

/* --- EMBED --- */
.l-embed {
  position: relative;
  padding-bottom: 56.25%;
  /* ratio 16.9 */
  height: 0;

  // &.m--rXXX {} // Ratio modifier
}

.l-embed > iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.page {
  min-height: 600px;
  overflow-x: clip;
  // margin: 0 8px;
}

.entry.m--big p {
  font-size: em(20);
  margin-top: em(10);
}

.entry__title {
  font-size: rem(33);
  margin: 0;
}

.l-padding {
  padding: 0 20px;
}

.pg-padding {
  padding: 1rem;
}

#contact-form {
  scroll-margin-top: 150px;
}

.website-logo {
  height: 60px;
}

.l-scrollbar {
  position: relative;
}

[class^="ps__rail"] {
  background-color: rgba($c-green, 0.6) !important;
  opacity: 1 !important;
}

.ps__rail-y,
.ps__thumb-y {
  width: 10px !important;
  right: 0 !important;
}

.ps__rail-x,
.ps__thumb-x {
  height: 10px !important;
}

[class^="ps__thumb"] {
  background-color: $c-green !important;
}

[class^="ps__rail"],
[class^="ps__thumb"] {
  border-radius: 6px !important;
}

@media screen and (max-width: 767px) {
  .l--desktop {
    display: none;
  }
}

@media screen and (min-width: $bp-l) {
  .website-logo {
    height: 120px;
  }

  .l--mobile {
    display: none;
  }
}
