/**
 * ==================== @ SERVICES
 */

.services {
	
	
/**
 * === Card product
 */	
	.product {
		
		.sv-u-m-1-3 {
			
			.product__img {
				display: block;
				float: right;
				padding: 0;

				img {
					border-radius: 20px 10px 10px 20px;
				}
			}
			
			.product-logo {
				background-color: #fff;
				border-radius: $radius;
				height: 50px;
				padding: 5px;
				left: 20px;
				position: absolute;
				top: 20px;
				width: 200px;

				picture {
					display: block;
					height: 100%;
				}
				
				img {
					display: block;
					height: 100%;
					margin: 0 auto;
					width: auto;
				}
			}
		}
		
		.sv-u-m-2-3 {
			padding: 1rem;			
		}
	}
	
	.product-card {
		position: relative;
		
		h1, &__title-mobile {
			font-size: em(45);
			font-weight: $fw-medium;
			margin-bottom: 0;
		}

		.product__img__mobile {
			padding: 0;
			position: relative;
			
			img {
				border-radius: 20px;
				width: 100%;
			}
		}
	
		.product-card__ref {
			color: $c-greynite;
			font-size: em(20);
			margin-top: 0;
			text-transform: uppercase;
		}
	
		.product-card__infos {
			display: flex;
			align-items: center;
			
			h4 {
				font-size: em(18);
				font-weight: $fw-semibold;
				text-transform: uppercase;
			}
			
			.avis {
				border-left: 10px solid $c-green;
				padding: 1rem;
				position: relative;

				&::before {
					@include background-svg($svg-quotes, #f8faeb);
					content: '';
					height: 70px;
					left: 1rem;
					position: absolute;
					top: 0;
					width: 80px;
				}
				
				h4 {
					position: relative;
					z-index: 2;
				}	
				
				p {
					position: relative;
				}
			}
			
			.price {
				font-size: em(30);
				
				.price-ttc {
					font-weight: $fw-medium;
					white-space: nowrap;
				}
				
				.price-suggested {
					display: block;
					font-size: em(12, 30);	
					white-space: nowrap;
				}
				
				.btn {
					font-size: em(14, 30);
					white-space: nowrap;
				}
			}
			
			&.m--sand {
				background-color: $c-whitegreen;
				border-radius: $radius;
				overflow: hidden;		
				margin-top: 2rem;
				padding: 1rem;
			}
			
			.avantages {
				padding: 1rem;
				
				ul {
					margin: 0;
					padding: 0 0 0 1rem;
				}				
			}
			
			.indexes {
				min-width: 10rem;
				
				li {
					font-size: em(14);
					font-weight: $fw-semibold;
					margin-bottom: .5rem;
				}
								
				.index {
					color: $c-white;
					display: inline-block;
					height: 32px;
					width: 44px;
					line-height: 32px;
					margin-right: 1.5rem;
					position: relative;
					text-align: center;
					
					&.m--A {--index-color: #00a06c}
					&.m--B {--index-color: #50b054}
					&.m--C {--index-color: #a3cc74}
					&.m--D {--index-color: #f3e61e}
					&.m--E {--index-color: #f0b418}
					&.m--F {--index-color: #eb8233}
					&.m--G {--index-color: #d71d1f}
					
					background-color: var(--index-color);
					
					&::after {
						content: "";
						height: 0;
						position: absolute;
						bottom: 0;
						right: -16px;
						width: 0;
						border-left: 16px solid var(--index-color);
						border-top: 16px solid transparent;
						border-bottom: 16px solid transparent;
					}
				}
				
				.level {
					display: inline-block;
					width: 2rem;
				}
				
			}
		}		
	}
	
	.products-related {
		background-color: $c-greylite;
		padding: 2rem 4rem;
		
		.grid-layout > div {
		
			&:nth-of-type(odd) .product-related {
				margin-right: 1rem;
			}
			
			&:nth-of-type(even) .product-related{ 
				margin-left: 1rem;
			}			
		}
		
		
		.product-related {
			border-radius: $radius;
			padding: 0;
			display: flex;
			overflow: hidden;
			width: 100%;
			
			.product__img picture {
				transition: transform 250ms;
				width: 100%;
				
				&:hover {
					transform: scale(1.05,1.05);
				}
			}
			
			.product__caption {
				background-color: $c-white;
				padding: 2rem 2rem 0 2rem;
				width: 100%;
				
				ul {
					margin-left: 0;
					margin-top: .5rem;
					padding-left: 1.5rem;
				}
			}
			
			.product__title {
				font-size: em(20);
				font-weight: $fw-bold;
				margin-top: 1rem;
				margin-bottom: 0;
			}
			
			.product__ref {
				font-size: em(13);
				margin-top: 0;
				text-transform: uppercase;
			}
			
			.product__perf {
				font-size: em(14);
				font-weight: $fw-semibold;
				text-transform: uppercase;
				margin-top: 4rem;
				margin-bottom: 0;
			}
			
			.product__price-label {
				font-size: em(11);
				margin-top: 3rem;
				margin-bottom: 0;
			}
			
			.product__price {
				font-size: em(22);
				font-weight: $fw-semibold;				
				margin-top: 0;
				text-transform: uppercase;
			}
		}		
	}

	.product-card__title-mobile, .product__img__mobile {
		@media screen and (min-width: 768px) {
			display: none;
		}

		@media screen and (max-width : 767px) {
			display: block;
		}
	}

	@media screen and (min-width: 768px) {
		.services-card {
			max-width: 90%;
			margin: 2rem auto;
		}
	}

	@media screen and (max-width : 767px) {	
		.product-card__title-mobile {
			margin-top: 1.2em;
			margin-bottom: .6em;
		}

		.product-card h1, .product-card__title-mobile {
			display: block;
			font-size: 30px;
			line-height: 1.16;
		}

		.product-logo__mobile {
			background-color: #fff;
			border-radius: $radius;
			position: absolute;
			bottom: 4%;
			right: 3.5%;
			width: 30%;
			padding: 3px 10px;

			img {
				width: 82%;
				margin-left: auto;
				margin-right: auto;
			}
		}

		.product-card h1 {
			margin-top: 20px;
		}

		.product-card__infos {
			flex-wrap: wrap;
		}

		.product__img {
			display: none;
		}

		.product-card .product-card__infos .avis {
			border-left: none;
			margin-left: 10px;
			padding-left: 30px;
			padding-bottom: 0;
			font-size: 15px;
			line-height: 1.5;
			margin-bottom: 20px;

			&::before {
				left: 30px;
			}

			&::after {
				content: '';
				position: absolute;
				width: 10px;
				height: calc(100% - 1.5em);
				top: 0;
				left: 0;
				transform: translateX(-100%);
				background-color: $c-green;
			}

			h4 {
				margin-bottom: 2em; 
			}

			p {
				margin-bottom: 0px;
				font-size: inherit;
			}
		}

		.product-card .product-card__infos {
			$padding: 13px;

			.price {
				padding-top: 20px;
				display: flex;
				justify-content: center;
				width: 100%;
				margin-bottom: 8px;
	
				&-block {
					display: flex;
					flex-direction: column;
				}

				.btn {
					margin-top: 17px;
				}
			}

			&.m--sand {
				border-radius: 10px;
				padding: $padding;
			}

			&-title {
				margin-top: 0;
			}

			.avantages {
				padding: $padding;
			}

			p {
				line-height: 1.07;
				margin-top: 0;
				margin-bottom: 1em;
			}

			.indexes {
				padding-left: calc(#{$padding} + 1rem);
				
				li {
					margin-bottom: 4px;
	
					&:nth-last-child(2) {
						margin-top: 16px;
					}

					&:last-child {
						margin-bottom: 0;
					}
				}

				.index {
					width: 32px;
				}
			}
		}

		.product .sv-u-m-1-3 .product__img {
			display: none;
		}
	}
}