/**
 * ==================== @ Really ?!
 */

.is-semantic {
	border: 0 !important;
	clip: rect(0 0 0 0) !important;
	display: block;
	height: 1px !important;
	margin: -1px !important;
	overflow: hidden !important;
	padding: 0 !important;
	position: absolute !important;
	width: 1px !important;
}

.is-hidden {
	display: none !important;
	overflow: hidden !important;
}

.max-1280\:overflow-hidden {
	@media not all and (min-width: $bp-1280) {
		overflow: hidden;
	}
}

.col-span-full {
	grid-column: 1 / -1;
}

.mt-8 {
	margin-top: 2rem;
}

.mb-8 {
	margin-bottom: 2rem;
}

.py-12 {
	padding-block: 3rem;
}

.space-y-12 {
	> * + * {
		margin-top: 3rem;
	}
}

.lg\:space-y-16 {
	@media (min-width: $bp-l) {
		> * + * {
			margin-top: 4rem;
		}
	}
}

.font-semibold {
	font-weight: 600;
}

.opacity-0 {
	opacity: 0;
}

.scale-95 {
	scale: 95% 95%;
}

.translate-x-full {
	translate: 100% 0;
}

.-translate-x-full {
	translate: -100% 0;
}

[x-cloak] {
  display: none !important;
}
