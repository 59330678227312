.figure {
  position: relative;
  display: inline-flex;
  border-radius: $radius;
  overflow: hidden;
  cursor: pointer;
  $el: &;

  &:hover {
    &::before {
      opacity: 1;
    }

    #{$el}__picto {
      opacity: 1;
    }
  }

  &::before {
    content: '';
    background-color: rgba($c-green, .5);
    position: absolute;
    inset: 0;
    opacity: 0;
    transition: opacity .2s;
  }

  &__picto {
    position: absolute;
    inset: auto 15px 16px auto;
    color: $c-greynite;
    @include circle($size: 50px, $color: $c-greynite, $bgc: rgba($c-whitegrey, .4), $fgc: $c-whitegrey);
  }

  picture,
  img {
    width: 100%;
  }

  img {
    aspect-ratio: 1.56;
    object-fit: cover;
    object-position: center;
    display: block;
  }

  @media (min-width: 1152px) {
    &__picto {
      inset: 50% auto auto 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
      transition: opacity .2s;
    }
  }
}