.img-comparison-block {
  display: flex;
  justify-content: space-between;
  font-size: 15px;
  margin-bottom: 8px;
}

.img-comparison-right {
  font-weight: 700;
}

img-comparison-slider {
  width: 100%;
  outline: none;
  --divider-width: 4px;

  img {
    border-radius: $radius;
    aspect-ratio: 1.56;
    object-fit: cover;
    object-position: center;
    // height: 100%;
    width: 100%;
  }
}