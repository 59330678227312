.key-points {
  position: relative;
  overflow: hidden;
  background-color: $c-green;
  padding: 0;

  & &__section {
    background-color: transparent;
  }

  &__ellipse {
    position: absolute;
    pointer-events: none;
    width: 930px;
    height: 930px;

    &1 {
      display: none;
    }

    &2 {
      fill: rgba($c-greynite, .1);
      bottom: 0;
      right: 0;
      transform: translate(50%, 50%);
    }
  }

  &__item {
    padding: 10px;

    &:nth-child(1) .svg-icon {
      width: 39px;
      height: 39px;
    }

    &:nth-child(2) .svg-icon {
      width: 36px;
      height: 33px;
    }

    &:nth-child(3) .svg-icon {
      width: 29px;
      height: 39px;
    }

    &:nth-child(4) .svg-icon {
      width: 25px;
      height: 35px;
    }

    p {
      font-size: em(14);
    }
  }

  &__layout {
    padding-top: 58px;
    padding-bottom: 58px;
  }

  &__title {
    &::before {
      content: none;
    }
  }

  @media (min-width: $bp-m) {
    &__ellipse {
      &1 {
        display: block;
        top: 0;
        left: 0;
        transform: translate(-50%, -50%);
        fill: rgba(#fff, .1);
      }
    }

    &__layout {
      padding-left: 8.3333%;
      padding-right: 8.3333%;
    }
  }
}