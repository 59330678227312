.alert {
  padding: 1rem;
  border-width: 1px;
  border-style: solid;
  border-radius: 3px;
  background-color: $c-white;
  font-size: 0.875rem;
  font-weight: 500;

  p {
    margin: 0;
    color: inherit;
  }

  &--info {
    color: $c-greynite;
  }

  &--success {
    color: $c-green-hover;
  }

  &--error {
    color: $c-red;
  }
}
