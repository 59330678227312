.tabbar {
  position: sticky;
  bottom: 0;
  inset-inline: 0;
  padding-block: 0.75rem;
  padding-inline: 0.25rem;
  background-color: $c-green;
  z-index: 100;

  @media (min-width: $bp-l) {
    position: fixed;
    top: calc(var(--headerHeight, 0px) + 3rem);
    left: auto;
    bottom: auto;
    width: 94px;
    border-radius: 0.5rem 0 0 0.5rem;
  }
}

.tabbar__links {
  --separator-size: 1px;

  display: flex;
  justify-content: center;
  gap: var(--separator-size);

  @media (min-width: $bp-l) {
    flex-direction: column;
  }
}

.tabbar__link-wrapper {
  flex: 1;
  position: relative;
  max-width: 6rem;

  @media (min-width: $bp-l) {
    padding-block: 0.75rem;
  }

  &:not(:last-child)::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 100%;
    transform: translateY(-50%);
    width: var(--separator-size);
    height: 3.125rem;
    background-color: $c-greynite;

    @media (min-width: $bp-l) {
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      width: 3.125rem;
      height: var(--separator-size);
    }
  }
}

.tabbar__link {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 0.25rem;
  width: 100%;
  outline: none;
  text-align: center;
  color: $c-greynite;
  transition-property: color;
  transition-duration: 150ms;

  @media (min-width: $bp-l) {
    font-size: 0.375rem;
  }

  &:hover,
  &:focus-visible {
    color: $c-white;
  }
}

.tabbar__link__icon {
  display: inline-flex;
  font-size: 1.875rem;

  .svg-icon {
    color: inherit;
  }
}

.tabbar__link__label {
  font-size: 0.625rem;
  font-weight: 600;
  letter-spacing: 0.1px;
  line-height: 1rem;

  @media (min-width: $bp-l) {
    font-size: 0.75rem;
  }
}
