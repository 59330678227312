.servicesList {
  padding: 2rem 0;
  /* margin-top: 40px; */
  position: relative;
  background-color: $c-greynite;
  overflow: hidden;

  >.pg__section {
    background-color: transparent;
  }

  &__ellipse {
    position: absolute;
    pointer-events: none;

    &1 {
      display: none;
      fill: rgba($c-green, .1);
      bottom: 0;
      right: 10px;
      width: 720px;
      height: 720px;
      transform: translateY(67px);
    }

    &2 {
      width: 597px;
      height: 597px;
      fill: rgba(#fff, .1);
      top: 10;
      left: 0;
      transform: translateX(-50%);
    }
  }

  .sv-g {
    justify-content: center;
  }

  .sv-u-1:last-child {
    padding: 1rem;
  }

  .service__item {
    color: $c-white;
    padding-top: 1rem;
    padding-bottom: 1rem;
    text-align: center;

    p:not(.icon-service) {
      color: $c-white;
      font-size: em(14);
      margin: .5rem .75rem;
    }
  }

  .services__text {
    font-size: 16px;
    color: $c-white;

    &::before {
      content: none;
    }
  }

  @media screen and (min-width:$bp-m) {
    .services__text {
      font-size: 20px;
    }

    &__ellipse {

      &1 {
        display: block;
      }
    }
  }
}