.social-links {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.social-link-wrapper {
  display: inline-flex;
}

.social-link {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 1.375rem;
  height: 1.375rem;
  border-radius: 50%;
  background-color: $c-greynite;
  font-size: 1rem;
  color: $c-white;
  transition-property: background-color;
  transition-duration: 150ms;

  .svg-icon {
    color: inherit;
  }

  &:hover,
  &:focus-visible {
    background-color: $c-green;
  }
}
