.text-image {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5rem;
  max-width: $layout-width;
  margin-inline: auto;
  padding-inline: $gutter;

  @media (min-width: $bp-s) {
    align-items: center;
    gap: 2rem;
  }

  @media (min-width: $bp-l) {
    flex-direction: row;
  }

  @media (min-width: $bp-1280) {
    gap: 0;
  }

  &--reverse {
    @media (min-width: $bp-l) {
      flex-direction: row-reverse;
    }
  }
}

.text-image__content {
  flex-grow: 1;
  flex-basis: 0;
  min-width: calc(min(24rem, 100%));

  @media (min-width: $bp-1280) {
    padding-inline: 3rem;
  }

  @media (min-width: $bp-xl) {
    padding-inline: 4rem;
  }

  > * + * {
    margin-top: 1.5rem;
  }
}

.text-image__image-wrapper {
  flex-basis: 50%;
  max-width: calc(min(40rem, 100%));
}

.text-image__image {
  display: block;
  border-radius: 0.5rem;
}
