.lp-reinsurance {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  position: relative;
  padding-top: 3rem;
  background-color: $c-greynite;
  overflow: hidden;
  color: $c-white;

  @media (min-width: $bp-s) {
    gap: 3rem;
    padding-bottom: 3rem;
  }

  @media (min-width: $bp-m) {
    flex-direction: row;
  }

  @media (min-width: $bp-xl) {
    padding-block: 4rem;
  }

  h2 {
    color: inherit;
  }
}

.lp-reinsurance__shape {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-35%, -55%);
  width: 930px;
  height: 930px;
  color: inherit;
  opacity: 0.1;
  pointer-events: none;
}

.lp-reinsurance__content {
  flex-grow: 999;
  flex-basis: 0;
}

.lp-reinsurance__image-wrapper {
  align-self: center;
  flex-grow: 1;
  flex-basis: 25%;
  min-width: calc(min(10rem, 100%));
  margin-left: calc(var(--lp-gutter) * -1);
  margin-right: calc(var(--lp-gutter) * -1);

  @media (min-width: $bp-s) {
    max-width: 16rem;
  }

  @media (min-width: $bp-m) {
    margin-left: 0;
  }
}

.lp-reinsurance__grid {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;

  @extend .l-unlist;
}

.lp-reinsurance-item {
  flex-basis: 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: calc(min(8rem, 100%));

  .svg-icon {
    color: inherit;
  }
}

.lp-reinsurance-item__icon {
  display: inline-flex;
  font-size: 50px;
  margin-bottom: 1rem;
}

.lp-reinsurance-item__title {
  font-weight: 600;
  text-align: center;
  text-wrap: balance;

  &::before {
    content: '';
    display: block;
    width: 25px;
    height: 5px;
    margin-bottom: 1.5rem;
    margin-inline: auto;
    background-color: $c-green;
  }
}
