/**
 * ==================== @ GRID LAYOUT
 */


.grid-layout {
  // .grid-col-txt {
  // 	display: flex;
  // 	flex-wrap: wrap;
  // 	align-items: center;
  // 	justify-content: center;
  // }

  .grid-col-content {
    padding: 17px 0 30px;
    position: relative;

    ul {
      padding-inline-start: 24px;
    }
  }

  figure:not(.img-rounded) {
    padding: 15px;
  }
}

@media screen and (min-width:$bp-m) {

  .grid-layout {
    margin: 0 auto;

    .grid-col-content {
      padding: 48px;
    }

    &.layout-2cols {

      .grid-col-txt:first-child {
        padding-right: .5rem;
      }

      .grid-col-txt:last-child {
        padding-left: .5rem;
      }
    }
  }
}

@media screen and (min-width:$bp-l) {
  .grid-col-content {
    ul {
      padding-inline-start: initial;
    }
  }
}

@media screen and (min-width:$bp-1280) {

  .default-section .grid-layout {

    &.layout-txt-img,
    &.layout-img-txt {

      figure:not(.img-rounded) {
        padding: 30px;
      }

      // .grid-col-content {
      //   max-width: 95%;
      // }
    }
  }
}