.callToAction {
  position: sticky;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: $c-whitegrey;
  padding: 4px 1px 5px;
  z-index: 1500;

  @at-root .is-nav-open & {
    display: none;
  }

  @media screen and (min-width: 768px) {
    display: none;
  }

  &-block {
    background-color: #fff;
    padding: 4px 1px 5px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    gap: 5px;
  }

  .btn {
    text-align: center;
    justify-content: center;
    padding: 5px;
    font-size: rem(10);
    gap: 7px;

    i {
      height: 28px;
      width: 28px;
    }
  }

  .btn-cta-call {
    padding: 0;

    i {
      width: 45px;
      height: 45px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-width: 5px;
    }

    .svg-icon {
      margin-top: 0;
    }
  }
}