.header {
  --navbar-height: 52px;
  --navigation-height: 80px;

  position: sticky;
  top: 0;
  inset-inline: 0;
  z-index: 1000;

  @media (min-width: $bp-l) {
    --navbar-height: 36px;

    position: sticky;
  }
}

.navbar {
  background-color: $c-green;

  .social-links,
  .navbar__links {
    @media not all and (min-width: $bp-l) {
      display: none;
    }
  }

  .navbar__links {
    align-items: center;
  }

  .navbar__link-wrapper {
    &:not(:last-of-type)::after {
      content: '';
      width: 0.625rem;
      height: 0.625rem;
      margin-inline: 1.25rem;
      border-radius: 50%;
      background-color: $c-white;
    }
  }

  .navbar__link {
    &:hover,
    &:focus-visible {
      color: $c-white;
    }
  }
}

.navbar-layout {
  display: flex;
  justify-content: flex-end;
  position: relative;
  max-width: $layout-width;
  min-height: var(--navbar-height);
  margin-inline: auto;
  padding-left: calc(#{$gutter} * 2 + var(--header-logo-width));
  padding-right: $gutter;

  @media (min-width: $bp-l) {
    justify-content: space-between;
  }
}

.navbar__logo {
  --logo-size: 3.875rem;
  --logo-padding: 0.75rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  position: absolute;
  top: 0;
  left: $gutter;
  width: var(--header-logo-width);
  padding: var(--logo-padding);
  border-radius: 0 0 0.5rem 0.5rem;
  background-color: $c-white;
  overflow: clip;
  z-index: 2;
  interpolate-size: allow-keywords;
  transition-property: height;
  transition-duration: 150ms;

  @media not all and (min-width: $bp-l) {
    height: auto;

    body.has-scrolled & {
      height: calc(var(--logo-size) + var(--logo-padding) * 2);
    }
  }

  @media (min-width: $bp-l) {
    --logo-size: 7.125rem;

    display: flex;
  }

  img {
    width: var(--logo-size);
  }
}

.navbar__logo__baseline {
  font-family: $font-baseline;
  font-size: 0.8125rem;
  line-height: 0.875rem;
  text-align: center;
  color: $c-green;
  transition-property: opacity;
  transition-duration: 150ms;

  @media not all and (min-width: $bp-l) {
    height: auto;

    body.has-scrolled & {
      opacity: 0;
    }
  }
}

.navbar__links {
  display: flex;
}

.navbar__link-wrapper {
  display: inline-flex;
  align-items: center;
}

.navbar__link {
  font-size: 0.875rem;
  font-weight: 600;
  text-transform: uppercase;
  transition-property: color;
  transition-duration: 150ms;
}

.navbar__burger {
  display: inline-flex;
  align-items: center;
  gap: 1rem;
  color: inherit;
  transition-property: color;
  transition-duration: 150ms;

  &:hover,
  &:focus-visible {
    color: $c-white;
  }

  @media (min-width: $bp-l) {
    display: none;
  }
}

.navbar__burger__label {
  font-size: 0.875rem;
  font-weight: 600;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

.navbar__burger__icon-wrapper {
  display: inline-flex;
  position: relative;
  width: 1.125rem;
  height: 1.125rem;
  font-size: 1.125rem;
}

.navbar__burger__icon {
  display: inline-flex;
  position: absolute;
  inset: 0;

  .svg-icon {
    color: inherit;
  }
}

.navigation {
  background-color: $c-whitegrey;

  @media not all and (min-width: $bp-l) {
    display: none;
  }
}

.navigation-layout {
  display: flex;
  justify-content: flex-end;
  max-width: $layout-width;
  min-height: var(--navigation-height);
  margin-inline: auto;
  padding-left: calc(#{$gutter} * 2 + var(--header-logo-width));
  padding-right: $gutter;
}

.navigation__links {
  display: flex;
  align-items: center;
}

.navigation__link-wrapper {
  display: inline-flex;
  align-items: center;

  &:not(:last-of-type)::after {
    content: '';
    width: 0.625rem;
    height: 0.625rem;
    margin-inline: 1.25rem;
    border-radius: 50%;
    background-color: $c-green;
  }

  &:first-child {
    .navigation__childs {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &:last-child {
    .navigation__childs {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}

.navigation__link {
  padding-block: 1rem;
  outline: none;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 0.5px;
  color: $c-greynite;
  transition-property: color;
  transition-duration: 150ms;

  &:hover,
  &:focus-visible {
    color: $c-green;
  }
}

.navigation__childs-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  padding: 1.5rem;
  border-radius: 0.75rem;
  background-color: $c-white;
  box-shadow: 0 0 0.625rem rgba(0, 0, 0, 0.25);
  z-index: 2;
}

.navigation__childs {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.75rem;
}

.navigation__child-wrapper {
  display: flex;

  .navigation-link {
    width: 260px;
  }
}

.navigation-mobile {
  position: absolute;
  top: var(--navbar-height);
  bottom: 0;
  inset-inline: 0;
  height: calc(100vh - var(--navbar-height) - var(--tabbarHeight, 0px));
  background-color: $c-white;
  transition-property: translate;
  transition-duration: 300ms;
  transition-timing-function: ease-out;

  @supports (height: 1dvh) {
    height: calc(100dvh - var(--navbar-height) - var(--tabbarHeight, 0px));
  }

  .navbar__links {
    flex-direction: column;
    align-items: flex-end;
    gap: 1.25rem;
  }

  .navbar__link {
    &:hover,
    &:focus-visible {
      color: $c-green;
    }
  }

  @media (min-width: $bp-l) {
    display: none;
  }
}

.navigation-mobile-layout {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 1.25rem;
  height: 100%;
  padding-block: 5rem 3rem;
  padding-inline: $gutter;
}

.navigation-mobile__links {
  --button-size: 2rem;

  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 1.25rem;
}

.navigation-mobile__link {
  display: flex;
  align-items: center;
  gap: 1rem;
  position: relative;
  min-height: var(--button-size);
  outline: none;
  color: $c-greynite;
  padding-right: calc(1rem + var(--button-size));
  transition-property: color;
  transition-duration: 150ms;

  &:hover,
  &:focus-visible {
    color: $c-green;

    .navigation-mobile__link__icon {
      border-color: $c-green;
    }
  }
}

.navigation-mobile__link__label {
  font-size: 1.25rem;
  font-weight: 600;
  letter-spacing: 0.5px;
  text-align: right;
}

.navigation-mobile__link__icon {
  flex-shrink: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  width: var(--button-size);
  height: var(--button-size);
  border: 0.25rem solid rgba($c-whitegrey, 0.4);
  border-radius: 50%;
  font-size: 0.625rem;
  transition-property: border-color;
  transition-duration: 150ms;
}

.navigation-mobile__childs-wrapper {
  position: absolute;
  inset: 0;
  background-color: $c-white;
  overflow-y: auto;
  overscroll-behavior: contain;
  z-index: 1;
  transition-property: translate;
  transition-duration: 300ms;
  transition-timing-function: ease-out;

  .navigation-mobile__link {
    margin-top: 1.5rem;
    padding-right: 0;
    padding-left: calc(#{$gutter} + var(--header-logo-width));
  }

  .navigation-mobile__link__icon {
    position: static;
    right: auto;
  }
}

.navigation-mobile__childs-layout {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 2rem;
  height: 100%;
  padding-inline: $gutter;
}

.navigation-mobile__childs {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  padding-bottom: 1.5rem;
}
