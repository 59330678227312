.news-slider {
  --bullet-size: 0.875rem;

  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  max-width: $layout-width;
  margin-inline: auto;
  padding-inline: $gutter;

  @media (min-width: $bp-s) {
    gap: 2rem;
  }

  @media (min-width: $bp-l) {
    flex-direction: row;
  }
}

.news-slider__main {
  flex-grow: 1;
  min-width: 0;
}

.news-slider__slide {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  height: 100%;

  @media (min-width: $bp-s) {
    gap: 2rem;
  }

  @media (min-width: $bp-m) {
    flex-direction: row-reverse;
  }
}

.news-slider__slide__content {
  padding-bottom: calc(1.5rem + var(--bullet-size));

  @media (min-width: $bp-s) {
    padding-bottom: calc(2rem + var(--bullet-size));
  }

  @media (min-width: $bp-m) {
    width: 40%;
    min-width: 240px;
    padding-top: 2rem;
  }
}

.news-slider__slide__title {
  margin: 0;
  font-size: 1.5rem;
  line-height: 1.25;

  @media (min-width: $bp-l) {
    font-size: 1.875rem;
  }

  &::before {
    content: none;
  }
}

.news-slider__slide__subtitle {
  margin-top: 0.75rem;
  line-height: 1.25;

  @media (min-width: $bp-l) {
    font-size: 1.25rem;
  }
}

.news-slider__slide__actions {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 1.5rem;
}

.news-slider__slide__image-wrapper {
  flex-grow: 1;
  width: 100%;
  max-width: 576px;
  aspect-ratio: 1.5;
  border-radius: 0.5rem;
  overflow: hidden;

  @media (min-width: $bp-m) {
    flex-basis: 0;
  }
}

.news-slider__slide__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.news-slider__pagination {
  display: flex;
  flex-wrap: wrap;
  gap: 1.25rem;
  position: absolute;
  bottom: 0 !important;
  left: 0 !important;
  z-index: 1;

  @media (min-width: $bp-m) {
    width: 40%;
  }
}

.news-slider__pagination__bullet {
  flex-shrink: 0;
  width: var(--bullet-size);
  height: var(--bullet-size);
  border-radius: 50%;
  background-color: #d0d5d9;
  cursor: pointer;

  &.is-active {
    background-color: $c-green;
  }
}

.news-slider__aside {
  flex-shrink: 0;
  flex-basis: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;

  @media (min-width: $bp-l) {
    flex-direction: column;
    gap: 2rem;
  }
}

.news-slider__button {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  min-width: 264px;
  padding: 1rem 1.25rem;
  border-radius: 0.5rem;
  background-color: $c-green;
  transition-property: box-shadow;
  transition-duration: 150ms;

  &:hover,
  &:focus-visible {
    box-shadow: 0 0 0.625rem rgba(#263c4e, 0.25);

    .news-slider__button__icon {
      background-color: $c-greydark;
    }
  }
}

.news-slider__button__label {
  font-size: 1.125rem;
  font-weight: 600;
  text-wrap: balance;

  span {
    font-size: 1.25rem;
    font-weight: 700;
  }
}

.news-slider__button__icon {
  flex-shrink: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 2.875rem;
  height: 2.875rem;
  border: 0.3125rem solid $c-white;
  border-radius: 50%;
  background-color: $c-greynite;
  font-size: 0.625rem;
  color: $c-white;
  transition-property: background-color;
  transition-duration: 150ms;

  .svg-icon {
    color: inherit;
  }
}
