/**
 * ==================== @ EDITO & SUBMENU / SERVICE GUIDE
 */

.edito-wrapper {
	background-color: $c-white;
	max-width: $layout-width;
	margin: 0 auto;	
}

.edito-content {
	position: relative;
}

.edito-section.edito-caption {
	padding-top: 2rem;
	padding-bottom: 30px;
}

.edito-submenu {

	> div {
		background-color: $c-whitegrey;
		border-radius: $radius-10;
		padding: 1rem;
		margin: 0 1rem 1rem 1rem;
	}
	
	ul {
		margin-bottom: 1rem;
	}
	
	li {
		margin: .5rem 0;
	}
	
	.btn.m--white {
		width: 100%;
	}
}

.edito-section {
	background-color: $c-whitegrey;
	border-radius: $radius-10;
	margin: 0 1rem 1rem 1rem;
	padding: 8px;

	figure {
		padding: 0 !important;
	}
	
	.service-edito & {
		background-color: $c-greylite;		
	}
	
	&.edito-caption {
		background-color: $c-greylite;
	}
	
	.edito-section-title {
		font-size: em(20);
	}

	.edito-wrapper-slider {
		margin-top: 35px;
		margin-bottom: 30px;

		h2, h3 {
			font-weight: 700;
			text-transform: none;
		}
	}
	
	.layout-txt .grid-col-txt,
	.layout-img .grid-col-img {
		padding-top: 1rem ;
	}
}

@media (min-width: $bp-m) {
	.edito-content {
		padding-left: 35%;
	}

	.edito-submenu {
		width: 30%;
		position: absolute;
		top: 0;
		left: 0;
	}
}