.dialog {
  position: relative;
  z-index: 10000;
}

.dialog__overlay {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.75);
  transition-property: opacity;
  transition-duration: 300ms;
  transition-timing-function: ease-out;
}

.dialog__wrapper {
  position: fixed;
  inset: 0;
  overflow-y: auto;
  overscroll-behavior: contain;
}

.dialog__panel-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  padding: 1rem;

  @media (min-width: $bp-s) {
    padding: 2rem;
  }
}

.dialog__panel {
  position: relative;
  width: 100%;
  max-width: 50rem;
  padding-block: 2rem;
  padding-inline: 1.5rem;
  border-radius: 0.5rem;
  background-color: $c-white;
  transition-property: opacity, scale;
  transition-duration: 300ms;
  transition-timing-function: ease-out;
}

.dialog__close {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 2.375rem;
  height: 2.375rem;
  border-radius: 50%;
  background-color: $c-greynite;
  font-size: 0.625rem;
  color: $c-white;
  transition-property: background-color;
  transition-duration: 150ms;

  &:hover,
  &:focus-visible {
    background-color: $c-greydark;
  }

  .svg-icon {
    color: inherit;
  }
}

.dialog__title-wrapper {
  margin-bottom: 1rem;
}

.dialog-agencies-list {
  --separator-size: 1px;

  margin-top: calc(var(--separator-size * -1));
  overflow: hidden;

  @media (min-width: $bp-l) {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    column-gap: 3rem;
  }
}

.dialog-agencies-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  position: relative;
  margin-top: var(--separator-size);

  &::after {
    content: '';
    position: absolute;
    top: 100%;
    inset-inline: 0;
    height: var(--separator-size);
    background-color: #b8bfc5;
  }
}

.dialog-agencies-item__label {
  font-size: 0.875rem;
  font-weight: 600;
  letter-spacing: 0.1px;
  line-height: 1.125rem;
  text-transform: uppercase;
}

.dialog-agencies-item__action {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding-block: 0.625rem;
  padding-inline: 0.75rem;
  outline: none;

  &:hover,
  &:focus-visible {
    .dialog-agencies-item__action__icon {
      background-color: $c-white;
    }
  }
}

.dialog-agencies-item__action__icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  border: 0.25rem solid $c-greenlite;
  border-radius: 50%;
  background-color: $c-green;
  font-size: 0.625rem;
  transition-property: background-color;
  transition-duration: 150ms;

  .svg-icon {
    color: inherit;
  }
}

.dialog-agencies-item__action__label {
  min-width: 6.625rem;
  font-size: 0.875rem;
  font-weight: 600;
  letter-spacing: 0.1px;

  @media not all and (min-width: $bp-s) {
    display: none;
  }
}
