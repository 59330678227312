.home-map {
  max-width: $layout-width;
  margin-inline: auto;
  padding-inline: $gutter;
}

.home-map__head {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  @media (min-width: $bp-s) {
    gap: 2rem;
  }

  @media (min-width: $bp-l) {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }
}

.home-map__content {
  > * + * {
    margin-top: 2rem;
  }

  .text {
    max-width: 40rem;
    text-wrap: balance;
  }
}

.home-map__legend {
  flex-shrink: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem 1rem;

  @media (min-width: $bp-l) {
    flex-direction: column;
  }
}

.home-map__legend__item {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;

  &--agency {
    .home-map__legend__item__icon {
      color: $c-green;
    }
  }

  &--partner {
    .home-map__legend__item__icon {
      color: $c-greynite;
    }
  }
}

.home-map__legend__item__icon {
  display: inline-flex;
  font-size: 2rem;

  .svg-icon {
    color: inherit;
  }
}

.home-map__legend__item__label {
  font-size: 0.9375rem;
  letter-spacing: 0.1px;
}

.home-map__map {
  height: 30rem;
  margin-top: 1.5rem;
  margin-inline: -$gutter;
  background-color: $c-whitegrey;
  overflow: hidden;

  @media (min-width: $bp-s) {
    height: 36rem;
    margin-top: 2rem;
    margin-inline: 0;
    border-radius: 0.5rem;
  }

  &.leaflet-container {
    font-family: inherit;

    &::after {
      font-family: inherit;
      padding-inline: $gutter;
      text-align: center;
    }
  }

  .leaflet-tile-pane {
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(-50%, -50%);
      width: calc(infinity * 1px);
      height: calc(infinity * 1px);
      background-color: $c-greynite;
      mix-blend-mode: soft-light;
      z-index: 2;
    }
  }

  .leaflet-popup-content-wrapper,
  .leaflet-popup-tip {
    box-shadow: 0 0 1.5rem rgba(0, 0, 0, 0.2);
    background-color: $c-white;
    font-family: inherit;
    color: inherit;
  }

  .leaflet-popup-content-wrapper {
    padding: 0;
    border-radius: 0.5rem;
  }

  .leaflet-popup-content {
    margin: 0;
    font-size: 1rem;
    line-height: normal;
  }
}

.home-map__tooltip {
  padding: 1.5rem;
  overflow-wrap: anywhere;

  > * + * {
    margin-top: 0.75rem;
  }
}

.home-map__tooltip__title {
  font-size: 1.375rem;
  font-weight: 600;
  letter-spacing: 0.1px;
  line-height: 1.625rem;
  text-transform: uppercase;
  color: $c-green;
}

.home-map__tooltip__address {
  font-size: 0.9375rem;
}

.home-map__tooltip__link {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.home-map__tooltip__link__icon {
  display: inline-flex;
}

.home-map__tooltip__link__label {
  font-size: 0.875rem;
  font-weight: 600;
  letter-spacing: 0.1px;
  color: $c-greynite;
}
