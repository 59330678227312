.related-articles {
  padding-top: 32px;
  padding-bottom: 32px;

  &__title {
    all: unset;
    font-size: rem(18);
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 0.8em;
    display: block;

    &::before {
      content: none;
    }
  }

  &__slider {
    &::before {
      content: '';
      position: absolute;
      inset: 0 0 0 auto;
      height: 100%;
      width: 132px;
      z-index: 1;
      background: linear-gradient(270deg, rgba(#fff, 1) 0%, rgba(#fff, 0) 100%);
    }

    .slick-arrow {
      transform: translateY(-100%) !important;
    }
  }

  & &__slide {
    background-color: transparent;
  }

  & &__img {
    border-bottom-color: transparent !important;
  }

  &__caption {
    width: 90%;
    padding-bottom: 0 !important;
  }

  .slick-prev {
    left: 20px !important;
  }

  .slick-next {
    right: 20px !important;
  }
}