/**
 * ==================== @ BREADCRUMB
*/

.breadcrumb__wrapper {
  background-color: $c-white;
  border-radius: $radius;
  margin: 0 auto;
  padding-block: 10px;
  padding-left: calc(var(--header-logo-width) + #{$gutter} * 2);
  max-width: $layout-width;
  position: relative;
  z-index: 1;
}

.breadcrumb {
  background-color: $c-white;

  .svg-icon:not(.home) {
    display: none;
  }
}

.breadcrumb__list {
  list-style: none;
  padding: 0;

  li {
    display: inline-block;
    font-size: em(10);
  }

  li + li::before {
    content: '/';
    display: inline-block;
    color: $c-black;
    font-style: normal;
    font-size: rem(8);
    margin: 0 2px;
  }

  a {
    color: $c-grey;
  }

  .svg-icon {
    vertical-align: 0;
  }
}

@media screen and (min-width: $bp-lx) {
  .breadcrumb__wrapper {
    background-color: transparent;
  }
}
