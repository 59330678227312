.text-video {
  &.pg__section {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .thumb-video {
    padding: 0 !important;
  }

  @media (min-width: $bp-m) {
    &__txt_right {
      padding-left: 8.3333%;
      display: flex;
      align-items: center;
    }
  }
}